<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Panel Settings Information</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <form role="form">
          <div class="col-sm-12">
            <div class="alert alert-info" v-if="alert_message">
              {{ alert_message }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Profile Info</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Brand Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="com_name"
                      id="com_name"
                      class="form-control input-sm"
                      placeholder="Organization Name"
                      type="text"
                      maxlength="20"
                      v-model="com_name"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label class="field_suggestion"
                      >This value will be used as a Panel name.</label
                    >
                    <label v-if="error_com_name" class="error" for="name">{{
                      error_com_name
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Phone No <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="com_phone"
                      id="com_phone"
                      class="form-control input-sm"
                      placeholder="Phone No"
                      type="text"
                      v-model="com_phone"
                      onfocus="this.removeAttribute('readonly');"
                    />
                    <label class="field_suggestion"
                      >This value will be used in Reseller and Cdr Login
                      Page.</label
                    >
                    <label v-if="error_com_phone" class="error" for="name">{{
                      error_com_phone
                    }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label">Address</label>
                  <div class="col-md-8">
                    <textarea
                      class="form-control input-sm"
                      name="com_address"
                      placeholder="Address"
                      id="com_address"
                      v-model="com_address"
                      onfocus="this.removeAttribute('readonly');"
                    ></textarea>
                    <label class="field_suggestion"
                      >This value will be used in Reseller and Cdr Login
                      Page.</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Panel UI Info</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label for="com_name_color" class="col-md-4 control-label"
                    >Panel Name Color</label
                  >
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="com_name_color"
                      class="form-control valid"
                      id="com_name_color"
                      v-model="com_name_color"
                      aria-invalid="false"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="login_header_color" class="col-md-4 control-label"
                    >Login Header Color</label
                  >
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="login_header_color"
                      class="form-control valid"
                      id="login_header_color"
                      aria-invalid="false"
                      v-model="login_header_color"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="login_bg_color" class="col-md-4 control-label"
                    >Login Background Color</label
                  >
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="login_bg_color"
                      class="form-control valid"
                      id="login_bg_color"
                      v-model="login_bg_color"
                      aria-invalid="false"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label
                    for="switch_header_color"
                    class="col-md-4 control-label"
                    >Panel Header Color</label
                  >
                  <div class="col-md-8">
                    <input
                      type="color"
                      name="switch_header_color"
                      class="form-control valid"
                      id="switch_header_color"
                      v-model="switch_header_color"
                      aria-invalid="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Logo and favicon</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-3 control-label">Previous Logo</label>
                  <div class="col-md-3">
                    <img
                      width="100"
                      class="img-responsive img-bordered img-thumbnail"
                      alt="Logo"
                      :src="logo"
                    />
                  </div>
                  <label class="col-md-3 control-label">Favicon</label>
                  <div class="col-md-3">
                    <img
                      width="50"
                      class="img-responsive img-bordered img-thumbnail"
                      alt="Logo"
                      :src="favicon"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-2 control-label">New Logo</label>
                  <div class="col-md-4">
                    <input
                      class="form-control input-sm"
                      name="logo_images"
                      type="file"
                      ref="file"
                      @change="onFileSelected"
                    />
                    <label class="field_suggestion"
                      >Min:100X100 px, Max:512X512 px.</label
                    >
                  </div>
                  <label class="col-md-2 control-label">New Ico</label>
                  <div class="col-md-4">
                    <input
                      class="form-control input-sm"
                      name="favicon"
                      type="file"
                      ref="file"
                      @change="onFileSelected2"
                    />
                    <label class="field_suggestion"
                      >Type: ico, Max: 80kb.</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddPanelSetting",
  data() {
    return {
      com_name: "",
      com_phone: "",
      com_address: "",
      com_name_color: "",
      login_header_color: "",
      login_bg_color: "",
      switch_header_color: "",
      logo: "",
      favicon: "",
      logo_images: "",
      favicon_images: "",
      id: "",
      logo_image_file: null,
      favicon_image_file: null,
      api_public_path: process.env.VUE_APP_APIRESELLERPUBLIC,

      error_com_name: false,
      error_com_phone: false,

      alert_message: "",
      error_name: false,
    };
  },
  created() {
    var access = this.com_verifyAllowOrNotSetting();
    if (access === false) {
      this.$router.push("dashboard");
    }
    var panelData = this.$store.getters.getPanelData;
    this.com_name = panelData.com_name;
    this.com_phone = panelData.com_phone;
    this.com_address = panelData.com_address;
    this.com_name_color = panelData.com_name_color;
    this.login_header_color = panelData.login_header_color;
    this.login_bg_color = panelData.login_bg_color;
    this.switch_header_color = panelData.switch_header_color;
    this.id = panelData.id;

    this.logo = "/assets/img/nologo.png";
    this.favicon = "/assets/img/nologo.png";

    var logoImgURL =
      this.api_public_path + "uploads/images/" + panelData.logo_images;
    this.checkIfImageExists(logoImgURL, (exists) => {
      if (exists) {
        this.logo = logoImgURL;
      }
    });

    var faviconImgURL =
      this.api_public_path + "uploads/images/" + panelData.favicon;
    this.checkIfImageExists(faviconImgURL, (exists) => {
      if (exists) {
        this.favicon = faviconImgURL;
      }
    });
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
        img.onerror = () => {
          callback(false);
        };
      }
    },

    formSubmit(e) {
      var panel = this.$route.params.panel;
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("com_name", this.com_name);
      form.append("com_phone", this.com_phone);
      form.append("com_address", this.com_address);
      form.append("com_name_color", this.com_name_color);
      form.append("login_header_color", this.login_header_color);
      form.append("login_bg_color", this.login_bg_color);
      form.append("switch_header_color", this.switch_header_color);
      form.append("logo_image_file", this.logo_image_file);
      form.append("favicon_image_file", this.favicon_image_file);
      form.append("_method", "put");

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("update-panel-setting/" + this.id, form, config)
        .then(function (response) {
          //console.log(response.data.result.settings);
          let panelparam = {
            panel: panel,
            panel_settings: response.data.result.settings,
          };
          rootObject.logo =
            rootObject.api_public_path +
            "uploads/images/" +
            response.data.result.settings.logo_images;
          rootObject.favicon =
            rootObject.api_public_path +
            "uploads/images/" +
            response.data.result.settings.favicon;
          rootObject.com_setPanelData(panelparam);
          rootObject.alert_message = "Setting Successfully Updated";
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      this.error_com_name = false;
      this.error_com_phone = false;

      if (!this.com_name) {
        this.error_com_name = "This field is required";
        has_error = true;
      }

      if (!this.com_phone) {
        this.error_com_phone = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.logo_image_file = event.target.files[0];
    },

    onFileSelected2(event) {
      //console.log(event.target.files[0]);
      this.favicon_image_file = event.target.files[0];
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
