var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xs-2"},[_c('router-link',{staticClass:"btn btn-sm btn-add",attrs:{"to":{ path: 'addrateplans' }}},[_c('i',{staticClass:"fa fa-plus-square"}),_vm._v("Add New VPN Rate Plan ")])],1)])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"box",attrs:{"id":"pagination"}},[_c('div',{staticClass:"box-header"},[_c('h3',{staticClass:"box-title"},[_vm._v("Total row found: "+_vm._s(_vm.rateplans.length))])]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-0024 table-bordered table-striped"},[_vm._m(1),_c('tbody',_vm._l((_vm.rateplans),function(data,index){return _c('tr',[_c('td',{staticClass:"tdCenter tdSN"},[_vm._v(_vm._s(++index))]),_c('td',{staticClass:"tdCenter"},[_c('router-link',{attrs:{"to":{
                          name: 'SaleingRatePlans',
                          params: { id: data.id },
                        },"title":"Rate Chart"}},[_vm._v(" "+_vm._s(data.name)+" ")])],1),_c('td',{staticClass:"tdCenter"},[_vm._v(" "+_vm._s(_vm.user_data.login)+" ")]),_c('td',{staticClass:"tdCenter"},[_vm._v(" "+_vm._s(data.created_at)+" ")]),_c('td',{staticClass:"tdCenter"},[_c('router-link',{staticClass:"btn btn-sm btn-payment",attrs:{"to":{
                          name: 'SaleingRatePlans',
                          params: { id: data.id },
                        },"title":"Rate Chart"}},[_c('i',{staticClass:"fa fa-th-list"}),_c('span',{staticStyle:{"top":"-1px"}},[_vm._v("Rate Plans")])])],1),_c('td',{staticClass:"tdCenter"},[(data.status == 1)?_c('span',{staticClass:"label label-success"},[_vm._v("Active")]):_c('span',{staticClass:"label label-danger"},[_vm._v("Inctive")])]),_c('td',{staticClass:"tdCenter"},[_c('router-link',{attrs:{"to":{
                          name: 'EditRatePlans',
                          params: { id: data.id },
                        },"title":"Edit Rate Plan"}},[_c('i',{staticClass:"ion ion-ios-compose table-edit"})])],1)])}),0)])])]),_c('div',{staticClass:"box-footer"})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-10"},[_c('h1',[_vm._v("VPN Rate Plan List")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"tdCenter tdSN"},[_vm._v("SN")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Rate Plan")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Parent")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Creation Date")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Rate Chart")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Status")]),_c('th',{staticClass:"tdCenter"},[_vm._v("Action")])])])
}]

export { render, staticRenderFns }