<template>
  <body
    class="hold-transition login-page"
    :style="'background-color:' + panelData.login_bg_color"
  >
    <div id="result" v-if="!loadNow">
      <p style="text-align: center">
        <br /><br /><br />
        Loading . . .
      </p>
    </div>
    <div v-if="loadNow">
      <h3 class="loginBillName" :style="'color:' + panelData.com_name_color">
        {{ panelData.com_name }}
      </h3>
      <div class="login-box">
        <div class="col-sm-6 col-sm-offset-3">
          <div class="modal-content">
            <div
              class="modal-header"
              :style="
                'padding: 0px; background: ' + panelData.login_header_color
              "
            >
              <h4>Admin Login</h4>
            </div>
            <div
              class="modal-body"
              :style="'border:2px solid ' + panelData.login_header_color"
            >
              <div class="row">
                <div
                  class="col-sm-6 col-sm-offset-3"
                  id="login_form"
                  style="text-align: center; padding: 20px 0px 50px 0px"
                  align="center"
                >
                  <form role="form" @submit="login">
                    <div align="center">
                      <img class="img_logo" id="img_logo" :src="logoImg" />
                    </div>

                    <div class="alert alert-info" v-if="info_message">
                      {{ info_message }}
                    </div>

                    <div v-if="takeResellerEmail">
                      <div class="form-group has-feedback">
                        <input
                          type="text"
                          style="padding: 5px 10px; height: 30px"
                          name="email"
                          id="email"
                          v-model="email"
                          class="form-control input-sm"
                          placeholder="Enter Your Valid Email"
                        />
                        <span
                          class="glyphicon glyphicon-envelope form-control-feedback"
                        ></span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="form-group has-feedback">
                        <input
                          type="text"
                          style="padding: 5px 10px; height: 30px"
                          name="username"
                          id="username"
                          v-model="username"
                          class="form-control input-sm"
                          placeholder="Username"
                        />
                        <span
                          class="glyphicon glyphicon-user form-control-feedback"
                        ></span>
                      </div>
                      <div class="form-group has-feedback">
                        <input
                          type="password"
                          id="password"
                          v-model="password"
                          style="padding: 5px 10px; height: 30px"
                          name="userPassword"
                          class="form-control input-sm"
                          placeholder="Password"
                        />
                        <span
                          class="glyphicon glyphicon-lock form-control-feedback"
                        ></span>
                      </div>
                    </div>
                    <div class="form-group"></div>
                    <button
                      type="submit"
                      name="commit"
                      value="Login"
                      class="btn btn-info btn-login btn-flat"
                      :style="
                        'background-color: ' +
                        panelData.login_header_color +
                        '; border:none;'
                      "
                    >
                      <span v-if="takeResellerEmail">Get Login Link</span>
                      <span v-else>Login</span>
                    </button>
                  </form>
                </div>
                <div class="col-sm-6 col-sm-offset-3" align="center">
                  <span style="text-align: right">
                    <router-link
                      :to="{ name: 'passwordReset' }"
                      style="color: rgb(184, 0, 0)"
                    >
                      Reset Password
                    </router-link>
                  </span>
                  <br /><br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 copyright-text"
        style="
          margin: 15px 0;
          font: 300 12px 'Roboto', sans-serif;
          text-transform: uppercase;
          letter-spacing: 2px;
          text-align: center;
        "
      >
        Copyright © 2019 - 2021 | Powered by
        <strong> {{ panelData.com_name }}</strong>
      </div>
    </div>
  </body>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      info_message: "",
      api_public_path: process.env.VUE_APP_APIRESELLERPUBLIC,
      loadNow: false,
      support_admin_id: "",
      logoImg: "/assets/img/nologo.png",
      takeResellerEmail: false,
      email: "",
      jwt_token: "",
    };
  },
  created() {
    var rootObject = this;
    var panel = this.$route.params.panel;
    if (panel == undefined) {
      rootObject.com_pageNotFound();
    } else {
      if (this.$store.getters.getLoginStatus) {
        axios.defaults.headers.common["panel"] = panel;
      }
      //Get Panel Configurations
      axios
        .get("panel-setting", {
          params: {
            panel_name: panel,
          },
        })
        .then(function (response) {
          //console.log(response.data);
          if (response.data.settings == null) {
            rootObject.com_pageNotFound();
          } else {
            let panelparam = {
              panel: panel,
              panel_settings: response.data.settings,
            };
            rootObject.com_setPanelData(panelparam);

            var logoImgURL =
              rootObject.api_public_path +
              "uploads/images/" +
              response.data.settings.logo_images;
            rootObject.checkIfImageExists(logoImgURL, (exists) => {
              if (exists) {
                rootObject.logoImg = logoImgURL;
                //$("#img_logo").attr('src', logoImgURL);
              }
            });

            if (response.data.settings.favicon) {
              $("#favicon").attr(
                "href",
                rootObject.api_public_path +
                  "uploads/images/" +
                  response.data.settings.favicon
              );
            } else {
              $("#favicon").attr("href", "/assets/img/symlex_logo.png");
            }
            window.document.title =
              "Admin Panel - " + response.data.settings.com_name.toUpperCase();
            rootObject.loadNow = true;
            rootObject.getRequestedLoginDataDecrypt();
          }
        })
        .catch(function (error) {
          rootObject.com_pageNotFound();
        });
    }
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
        img.onerror = () => {
          callback(false);
        };
      }
    },

    getRequestedLoginDataDecrypt() {
      var urlParams = new URLSearchParams(window.location.search);
      var credentials = localStorage.getItem("credentials");
      if (urlParams.has("credentials")) {
        credentials = urlParams.get("credentials");
      }

      localStorage.removeItem("support_admin_id");
      if (credentials) {
        credentials = credentials.split(" ").join("+");
        var decodedString = this.com_DecryptString(
          credentials,
          "SymKolpo321(fd#]"
        );
        var decodedData = JSON.parse(decodedString);
        this.username = decodedData.username;
        this.password = decodedData.password;
        this.support_admin_id = decodedData.support_admin_id;
        localStorage.setItem("support_admin_id", decodedData.support_admin_id);
        axios.defaults.headers.common["support_admin_id"] =
          decodedData.support_admin_id;
        this.loginAttept();
        //console.log(decodedData);
      }

      if (urlParams.has("emailValidatedLogin")) {
        credentials = urlParams.get("emailValidatedLogin");
        credentials = credentials.split(" ").join("+");
        var decodedString = this.com_DecryptString(credentials);
        var decodedData = JSON.parse(decodedString);
        this.username = decodedData.username;
        this.password = decodedData.password;
        this.validateLoginToken(decodedData);
        //console.log(decodedData);
      }
      //console.log(credentials);
    },

    validateLoginToken(decodedData) {
      var rootObject = this;
      var url = "validate_login_token";
      var param = {
        id: decodedData.id,
        token: decodedData.verify_token,
      };

      loader.show();
      axios
        .post(url, param)
        .then(function (response) {
          loader.hide();
          rootObject.info_message = response.data.message;
          //console.log(response);
          if (response.data.validate == true) {
            rootObject.loginAttept();
          } else {
            rootObject.username = "";
            rootObject.password = "";
          }

          setTimeout(function () {
            rootObject.info_message = "";
          }, 5000);
        })
        .catch(function (error) {
          let alertmessage = "";
          if (error.response.data) {
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
          }
          loader.hide();
          //console.log(alertmessage);
          rootObject.info_message = alertmessage;
          setTimeout(function () {
            rootObject.info_message = "";
          }, 3000);
        });
    },

    login(e) {
      e.preventDefault();
      //console.log(this.username + ' ' + this.password)
      if (!this.username) {
        toastr.error("Username is Mandatory", "Required !!!");
        $("#username").focus();
        return 0;
      }

      if (!this.password) {
        toastr.error("Password is Mandatory", "Required !!!");
        $("#password").focus();
        return 0;
      }

      localStorage.removeItem("support_admin_id");

      if (this.takeResellerEmail) {
        this.saveEmailAndLoginLinkToEmail();
      } else {
        this.loginAttept();
      }
    },

    loginAttept() {
      var rootObject = this;
      var panel = localStorage.getItem("panel");
      localStorage.removeItem("credentials");
      loader.show();
      axios
        .post("login", {
          login: this.username,
          password: this.password,
          panel: panel,
        })
        .then(function (response) {
          rootObject.loginProceedMethod(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          if (error.response.data) {
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
          }
          loader.hide();
          //console.log(alertmessage);
          rootObject.info_message = alertmessage;
          setTimeout(function () {
            rootObject.info_message = "";
          }, 3000);
        });
    },

    loginProceedMethod(param) {
      //console.log(param);
      var rootObject = this;

      //Take Email By Force If It is Email Start ----
      var jwt_token = param.data.token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + jwt_token;
      axios
        .get("profile")
        .then(function (response) {
          var decodedString = rootObject.com_DecryptString(response.data.user);
          var userData = JSON.parse(decodedString);
          if (!userData.email || !userData.reseller.email) {
            rootObject.takeResellerEmail = true;
            rootObject.jwt_token = jwt_token;
            loader.hide();
          } else {
            rootObject.proceedToDashboard(param);
          }
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
        });
      //End----

      //rootObject.proceedToDashboard(param);
    },

    proceedToDashboard(param) {
      let loginparam = {
        jwt_token: param.data.token,
        redirect_url: "dashboard",
      };
      this.com_UpdateTokenAndTime(param.data.token);
      this.com_setLoginData(loginparam);
    },

    saveEmailAndLoginLinkToEmail() {
      loader.show();
      var rootObject = this;
      var param = {
        email: this.email,
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.jwt_token;
      axios
        .post("update-email", param)
        .then(function (response) {
          // console.log(response);
          // loader.hide();
          if (response.data.result) {
            rootObject.info_message = response.data.message;
            setTimeout(function () {
              rootObject.info_message = "";
            }, 10000);
          }
          //console.log(response);
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
        });
    },
  },
  computed: {
    ...mapGetters({
      panelData: "getPanelData",
    }),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-page {
  height: 100vh;
}
</style>
