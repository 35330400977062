<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Search VPN Subscription Batch</h1>
        </div>
        <div class="col-xs-4 pull-right">
          <router-link :to="{ name: 'AddPinBatchs' }" class="btn btn-sm btn-add">
            <i class="fa fa-plus-square"></i> Generate VPN Subscription Batch
          </router-link>
          <a class="btn btn-sm btn-add refresh" @click="refreshNow">
            <i class="fa fa-refresh"></i> Refresh
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-3 form-group">
            <input
              placeholder="Batch Name"
              type="text"
              v-model="description"
              name="description"
              id="description"
              class="form-control input-sm"
              value
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-md-3 form-group" style="margin-bottom: 0">
            <select
              title
              name="limit"
              id="limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="display_limit"
              v-select="display_limit"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-xs-6 col-md-3 ZRP form-group btnLeft">
            <a class="btn btn-sm btn-search" id="apply" @click="dopagination(0)">
              <i class="fa fa-search"></i> Search
            </a>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">{{ alert_message }}</div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }} Showing {{ dispplayFrom }} to
                {{ dispplayTo }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">VPN Subscription Batch Name</th>
                      <th class="tdCenter">Total Subscription</th>
                      <th class="tdCenter">Creation Date</th>
                      <th class="tdCenter">Download</th>
                      <th class="tdCenter" style="width: 200px">Subscription Status</th>
                      <th class="tdCenter">Activate All Subscription</th>
                      <th class="tdCenter displaynone">Trasfer Subscription</th>
                      <th class="tdCenter">Deactivate All Subscription</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in pinBatchListArr" :key="data.id">
                      <td class="tdCenter tdSN">{{ parseInt(sl) + parseInt(index) }}</td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'PinListBatchWises',
                            params: { id: data.lot_id },
                          }"
                          title="Batch Name"
                        >{{ data.description }}</router-link>
                      </td>
                      <td class="tdCenter">{{ data.numpin }}</td>
                      <td>{{ data.creationdate }}</td>
                      <td class="tdCenter">
                        <a
                          title="Download"
                          class="btn btn-sm btn-payment"
                          @click="downloadNow(data.lot_id, data.description)"
                        >
                          <i class="fa fa-download"></i>
                          <span style="top: -1px">Download</span>
                        </a>
                      </td>
                      <td class="tdCenter">
                        <span class="label label-success">
                          Active :
                          {{
                          pinStatusArr[data.lot_id]
                          ? pinStatusArr[data.lot_id].active
                          ? pinStatusArr[data.lot_id].active
                          : 0
                          : 0
                          }}
                        </span>
                        <span class="label label-danger">
                          Inactive :
                          {{
                          pinStatusArr[data.lot_id]
                          ? pinStatusArr[data.lot_id].inactive
                          ? pinStatusArr[data.lot_id].inactive
                          : 0
                          : 0
                          }}
                        </span>
                      </td>
                      <td class="tdCenter">
                        <button
                          class="btn btn-sm btn-payment status"
                          @click="changeStatus(data.lot_id, 1)"
                        >
                          <i class="fa fa-list" aria-hidden="true"></i>
                          <span>Activate All</span>
                        </button>
                      </td>

                      <td
                        class="tdCenter displaynone"
                        v-if="
                          (subscribeStatuArr[data.lot_id]
                            ? subscribeStatuArr[data.lot_id].unSubscribed
                              ? subscribeStatuArr[data.lot_id].unSubscribed
                              : 0
                            : 0) > 0
                        "
                      >
                        <button
                          title="Transfer"
                          class="btn btn-sm btn-payment transfer-pin"
                          @click="transferPin(data.lot_id)"
                        >
                          <i class="fa fa-exchange" aria-hidden="true"></i>
                          <span>Transfer</span>
                        </button>
                        fff
                      </td>
                      <td class="tdCenter displaynone" v-else>
                        <button
                          title="Transfer"
                          class="btn btn-sm btn-payment transfer-pin disabled"
                        >
                          <i class="fa fa-exchange" aria-hidden="true"></i>
                          <span>Transfer</span>
                        </button>
                        ddd
                      </td>
                      <td class="tdCenter">
                        <button
                          style="background-color: #dd4b39 !important"
                          class="btn btn-sm btn-payment status"
                          @click="changeStatus(data.lot_id, 2)"
                        >
                          <i class="fa fa-list" aria-hidden="true"></i>
                          <span>Deactivate All</span>
                        </button>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'EditBatchs',
                            params: { id: data.lot_id },
                          }"
                          title="Edit"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>&nbsp;
                      </td>
                    </tr>
                    <tr v-if="pinBatchListArr.length == 0">
                      <td colspan="10" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >« Previous</a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">..</span>
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">{{ index }}</span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a v-if="next_page_url" href="#" @click="dopagination(nextPage)" class="next">Next »</a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "PinBatchList",
  data() {
    return {
      pinBatchListArr: [],
      pinStatusArr: {},
      subscribeStatuArr: {},
      description: "",

      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      status: "",
      sl: 1,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    this.loadPinBatchList();
  },
  methods: {
    loadPinBatchList() {
      loader.show();
      var rootObject = this;
      axios
        .get("batches")
        .then(function (response) {
          //console.log(response);
          rootObject.populatePinBatchListData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;

      let querystr =
        "name=" + this.description + "&paginate=" + this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("batches?" + querystr)
        .then(function (response) {
          rootObject.populatePinBatchListData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populatePinBatchListData(response) {
      var responseData = response.data.batchData;
      //console.log(responseData);
      this.sl = parseInt(responseData.from);
      this.pinBatchListArr = responseData.data;
      this.pinStatusArr = response.data.pinsData.lotsPinsArray;
      this.subscribeStatuArr = response.data.pinsData.lotsSubscriptionPinsArray;

      //console.log(this.pinStatusArr);

      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    transferPin(lot_id) {
      var rootObject = this;
      let msg = "";
      let subscribeStatuArr = this.subscribeStatuArr;
      let subscribedPin = subscribeStatuArr[lot_id]
        ? subscribeStatuArr[lot_id].subscribed
          ? subscribeStatuArr[lot_id].subscribed
          : 0
        : 0;
      let unSubscribedPin = subscribeStatuArr[lot_id]
        ? subscribeStatuArr[lot_id].unSubscribed
          ? subscribeStatuArr[lot_id].unSubscribed
          : 0
        : 0;

      if (subscribedPin == 0 && unSubscribedPin > 0) {
        msg =
          "No subscribed Subscription found! Do you really want to Transfer this Subscription Batch to child?";
      } else {
        msg =
          subscribedPin +
          " Subscribed Subscription found!. You can transfer " +
          unSubscribedPin +
          " Subscription to your child.";
      }

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            rootObject.$router.push("transfer_lot/" + lot_id);
          },
          no: function () {
            //return;
          },
        },
      });
    },

    changeStatus(lot_id, status) {
      var rootObject = this;
      let msg = "";
      let pinStatusArr = this.pinStatusArr;
      let activepin = pinStatusArr[lot_id]
        ? pinStatusArr[lot_id].active
          ? pinStatusArr[lot_id].active
          : 0
        : 0;
      let inactivepin = pinStatusArr[lot_id]
        ? pinStatusArr[lot_id].inactive
          ? pinStatusArr[lot_id].inactive
          : 0
        : 0;

      if (status == 2) {
        if (activepin == 0) {
          $.alert("All Subscription's are already deactivated");
          return 0;
        } else {
          msg =
            activepin +
            " Subscription's is active. Are you sure, you want to deactivate " +
            activepin +
            " Subscription's?";
        }
      }

      if (status == 1) {
        if (inactivepin == 0) {
          $.alert("All Subscription's are already deactivated");
          return 0;
        } else {
          msg =
            inactivepin +
            " Subscription's is inactive. Are you sure, you want to activate " +
            inactivepin +
            " Subscription's?";
        }
      }

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            loader.show();
            const form = new FormData();
            form.append("lot_id", lot_id);
            form.append("operation_type", status);
            var config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            axios
              .post("batch-pin-activation", form, config)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "Status successfully changed";
                  loader.hide();
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                loader.hide();
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            //return;
          },
        },
      });
    },

    downloadNow(lot_id, batch_name) {
      var rootObject = this;
      loader.show();
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      };
      axios
        .get("/batch-pin-download/" + lot_id, config)
        .then(function (response) {
          //console.log(response);
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob, { type: "text/plain" });
          a.href = url;
          var prefix = batch_name.trim().replace(" ", "_") + "_";
          var file_name = prefix.concat(rootObject.getFormattedTime());
          var ext = ".csv";
          a.download = file_name.concat(ext);
          document.body.append(a);
          loader.hide();
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(function (error) {
          rootObject.com_errorHandel(error);
          //console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
          console.log(alertmessage);
        });
    },

    downloadZipNow(lot_id, batch_name) {
      var rootObject = this;
      loader.show();
      axios
        .get("batch-pin-download-zip/" + lot_id)
        .then(function (response) {
          //console.log(response);
          loader.hide();
          let resultArr = response.data.split("downloadLink:");
          var a = document.createElement("a");
          window.open(resultArr[1], "_blank");
        })
        .catch(function (error) {
          rootObject.com_errorHandel(error);
          //console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
          console.log(alertmessage);
        });
    },

    refreshNow() {
      this.description = "";
      this.display_limit = 50;
      this.loadPinBatchList();
    },

    getFormattedTime() {
      var today = new Date();
      var y = today.getFullYear();
      var m = today.getMonth() + 1;
      var d = today.getDate();
      return y + "-" + m + "-" + d;
    },
  },

  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.displaynone {
  display: none;
}
</style>
