<template>
  	<PaymentReport />
</template>

<script>

// @ is an alias to /src
import PaymentReport from "@/components/reports/PaymentReport.vue";

export default {
  name: "PaymentReportView",
  components: {
    PaymentReport,
  },
};
</script>
