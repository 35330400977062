<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-sm-4">
          <h1>VPN Base Subscription Plan</h1>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                {{ base_plan.name }}
                <span v-if="rateplans.length >= 0"
                  >, Total : {{ rateplans.length }}</span
                >
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Plan Name</th>
                      <th class="tdCenter">Rate Plan</th>
                      <th class="tdCenter">Days</th>
                      <th class="tdCenter">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in rateplans">
                      <td class="tdCenter tdSN">{{ ++index }}</td>
                      <td class="tdCenter">{{ data.plan_name }}</td>
                      <td class="tdCenter">
                        {{ base_plan.name }}
                      </td>
                      <td class="tdCenter">{{ data.no_of_days }}</td>
                      <td class="tdCenter">{{ data.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer">
              <div class="pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "BaseRatePlan",
  data() {
    return {
      rateplans: {},
      base_plan: {},
    };
  },
  created() {
    loader.show();
    var rootObject = this;
    axios
      .get("get-base-rate?isWithBaseRate=yes")
      .then(function (response) {
        //console.log(response.data);
        rootObject.rateplans = response.data.base_rates;
        rootObject.base_plan = response.data.base_plan;
        loader.hide();
      })
      .catch(function (error) {
        loader.hide();
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });
  },
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
