<template>
  	<SaleingRatePlan />
</template>

<script>

// @ is an alias to /src
import SaleingRatePlan from "@/components/SaleingRatePlan.vue";

export default {
  name: "SaleingRatePlans",
  components: {
    SaleingRatePlan,
  },
};
</script>
