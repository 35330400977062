import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ResellerLists from "../views/ResellerList.vue";
import PinLists from "../views/PinList.vue";
import PinListBatchWises from "../views/PinListBatchWise.vue";
import AddNewResellers from "../views/AddNewReseller.vue";
import EditResellers from "../views/EditReseller.vue";
import loginView from "../views/Login.vue";
import AddSinglePins from "../views/AddSinglePin.vue";
import AddRatePlans from "../views/AddRatePlan.vue";
import BaseRatePlans from "../views/BaseRatePlan.vue";
import RatePlans from "../views/RatePlan.vue";
import EditRatePlans from "../views/EditRatePlan.vue";
import SaleingRatePlans from "../views/SaleingRatePlan.vue";
import AddSaleRate from "../views/AddSaleRate.vue";
import EditSaleRates from "../views/EditSaleRate.vue";
import TransferLots from "../views/TransferLot.vue";
import AddPinBatchs from "../views/AddPinBatch.vue";
import EditBatchs from "../views/EditBatch.vue";
import PinBatchLists from "../views/PinBatchList.vue";
import ResellerConnectedUsers from "../views/ResellerConnectedUser.vue";
import AddPanelSettings from "../views/AddPanelSetting.vue";
import PinSubscriptionReportView from "../views/reports/PinSubscriptionReport.vue";
import OnlineTransactionReportView from "../views/reports/OnlineTransactionReport.vue";
import ResellerTransactionReportView from "../views/reports/ResellerTransactionReport.vue";
import PaymentReportView from "../views/reports/PaymentReport.vue";
import IndividualSoldPinReportView from "../views/reports/IndividualSoldPinReport.vue";
import HourlySubscriptionReportView from "../views/reports/HourlySubscriptionReport.vue";
import passwordResetView from "../views/PasswordReset.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:panel",
    redirect: { name: 'loginView' }
  },
  {
    path: "/:panel/dashboard",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/login",
    name: "loginView",
    component: loginView,
  },
  {
    path: "/:panel/resellerlist",
    name: "ResellerLists",
    component: ResellerLists,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/resellerlist/:id",
    name: "EditResellers",
    component: EditResellers,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/subscriptionlist",
    name: "PinLists",
    component: PinLists,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/subscriptionlist/:id",
    name: "PinListBatchWises",
    component: PinListBatchWises,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/addresellers",
    name: "AddNewResellers",
    component: AddNewResellers,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/addsinglesubscription",
    name: "AddSinglePins",
    component: AddSinglePins,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/addrateplans",
    name: "AddRatePlans",
    component: AddRatePlans,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/baserateplans",
    name: "BaseRatePlans",
    component: BaseRatePlans,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/rateplans",
    name: "RatePlans",
    component: RatePlans,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/edit_rateplans/:id",
    name: "EditRatePlans",
    component: EditRatePlans,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/rateplans/:id",
    name: "SaleingRatePlans",
    component: SaleingRatePlans,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/addsalerate/:id",
    name: "AddSaleRate",
    component: AddSaleRate,
    meta: { requiresAuth: true },
  },
  {
    path: "/:panel/editsalerate/:id",
    name: "EditSaleRates",
    component: EditSaleRates,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/addsubscriptionbatchs",
    name: "AddPinBatchs",
    component: AddPinBatchs,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/edit_batch/:id",
    name: "EditBatchs",
    component: EditBatchs,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/transfer_lot/:id",
    name: "TransferLots",
    component: TransferLots,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/subscriptionbatchlist",
    name: "PinBatchLists",
    component: PinBatchLists,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/resellerconnectedusers",
    name: "ResellerConnectedUsers",
    component: ResellerConnectedUsers,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/panelsettings",
    name: "AddPanelSettings",
    component: AddPanelSettings,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/pinsubscriptionreport",
    name: "PinSubscriptionReportView",
    component: PinSubscriptionReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/onlinetransactionreport",
    name: "OnlineTransactionReportView",
    component: OnlineTransactionReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/transactionreport",
    name: "ResellerTransactionReportView",
    component: ResellerTransactionReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/paymentreport",
    name: "PaymentReportView",
    component: PaymentReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/individualsoldsubscriptionreport",
    name: "IndividualSoldPinReportView",
    component: IndividualSoldPinReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/:panel/hourlysubscriptionreport",
    name: "HourlySubscriptionReportView",
    component: HourlySubscriptionReportView,
    meta: { requiresAuth: true }
  },
  {
    path: "/pagenotfound",
    name: "pagenotfound",
    component: () => import("../components/404NotFound.vue")
  },
  {
    path: "/:panel/pagenotfound",
    name: "pagesnotfound",
    component: () => import("../components/404NotFound.vue")
  },
  {
    path: "/:panel/logout",
    redirect: { name: 'loginView' }
  },
  {
    path: "/:panel/passwordReset",
    name: "passwordReset",
    component: passwordResetView,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt_token") == null) {
      var panelName = to.params.panel;
      if (panelName) {
        next({
          name: "loginView",
          params: { panel: panelName }
        });
      } else {
        next({
          path: "pagenotfound"
        });
      }

    } else {
      next();
    }
  } else {
    if (to.name == 'loginView') {
      if (localStorage.getItem("jwt_token") == null) {
        next();
      } else {

        /*
          Start:
          When a user is already logged in and new user is trying to login 
          with support login then old session is cleared before route enter
        */
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('credentials')) {
          let credentials = urlParams.get('credentials');
          console.log("Cleared !");
          localStorage.removeItem("jwt_token");
          localStorage.setItem("credentials", credentials);
          next();
        }
        //End:

        var panelName = to.params.panel;
        next({
          name: "Home",
          params: { panel: panelName }
        });
      }
    } else {
      next();
    }
  }
});

export default router;
