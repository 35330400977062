<template>
  	<EditBatch />
</template>

<script>

// @ is an alias to /src
import EditBatch from "@/components/EditBatch.vue";

export default {
  name: "EditBatchs",
  components: {
    EditBatch,
  },
};
</script>
