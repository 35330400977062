<template>
  	<ResellerConnectedUser />
</template>

<script>

// @ is an alias to /src
import ResellerConnectedUser from "@/components/ResellerConnectedUser.vue";

export default {
  name: "ResellerConnectedUsers",
  components: {
    ResellerConnectedUser,
  },
};
</script>
