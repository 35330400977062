<template>
  	<BaseRatePlan />
</template>

<script>

// @ is an alias to /src
import BaseRatePlan from "@/components/BaseRatePlan.vue";

export default {
  name: "BaseRatePlans",
  components: {
    BaseRatePlan,
  },
};
</script>
