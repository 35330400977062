<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-sm-4">
          <h1>VPN Rate Plan : {{ plan_list.name }}</h1>
        </div>
        <div class="col-xs-4 pull-right">
          <!-- <router-link :to="{ name: 'AddSaleRate' }" class="btn btn-sm btn-add">
            <i class="fa fa-plus-square"></i> Add Sales Rate
          </router-link> -->
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                {{ plan_list.name }} Total : {{ rate_list.length }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Plan Name</th>
                      <th class="tdCenter">Rate Plan</th>
                      <th class="tdCenter">Days</th>
                      <th class="tdCenter">Price</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in rate_list" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(index) + 1 }}
                      </td>
                      <td class="tdCenter">
                        {{ data.plan_name }}
                      </td>
                      <td class="tdCenter">{{ plan_list.name }}</td>
                      <td class="tdCenter">
                        {{ data.no_of_days }}
                      </td>
                      <td class="tdCenter">{{ data.price }}</td>
                      <td class="tdCenter">
                        <span
                          v-if="data.status == 1"
                          class="label label-success"
                          >Active</span
                        >
                        <span v-else class="label label-danger">Inactive</span>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'EditSaleRates',
                            params: { id: custom_id + '_' + data.id },
                          }"
                          title="Edit Rate"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "SaleingRatePlan",
  data() {
    return {
      custom_id: "",
      rate_list: {},
      plan_list: {},
    };
  },
  created() {
    loader.show();
    var rootObject = this;

    this.custom_id = this.$route.params.id;

    var plan_id = this.$route.params.id;
    axios
      .get("get-rates-by-plan-id/" + plan_id)
      .then(function (response) {
        //console.log(response.data);
        rootObject.rate_list = response.data.rates;
        rootObject.plan_list = response.data.plan;
        loader.hide();
      })
      .catch(function (error) {
        loader.hide();
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });
  },
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
