<template>
  <header class="main-header">
    <router-link
      :to="{ name: 'Home' }"
      class="logo"
      :style="'background: ' + panelData.switch_header_color"
      style="color: #fff !important; font-weight: normal !important"
    >
      <span class="logo-mini"
        ><b>{{ panelData.com_name.toUpperCase() }}</b></span
      >
      <span class="logo-lg">{{ panelData.com_name.toUpperCase() }}</span>
    </router-link>
    <nav
      class="navbar navbar-static-top"
      role="navigation"
      :style="'background: ' + panelData.switch_header_color"
    >
      <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li
            class="user user-menu pin-limit-status-head"
            v-if="userData.user_level == 4"
          >
            <a
              href="#"
              style="padding-top: 17px"
              title="Total Subscriptions"
              v-if="pinlimit == '-1'"
            >
              <i class="top-i" id="pinLimitMgs"
                ><span
                  style="padding: 2px 5px; border-radius: 10px"
                  class="bg-gray"
                  >VPN Subscription: Unlimited</span
                ></i
              >
            </a>
            <a
              href="#"
              style="padding-top: 17px"
              title="Total Subscriptions"
              v-else
            >
              <i class="top-i" id="pinLimitMgs"
                ><span
                  style="padding: 2px 5px; border-radius: 10px"
                  class="bg-gray"
                  >VPN Subscription: {{ pinlimit }}</span
                ></i
              >
            </a>
          </li>
          <!-- <li class="user user-menu">
            <a href="#" style="padding-top: 17px" title="Balance Limit">
              <i
                class="top-i bg-gray"
                style="padding: 2px 5px; border-radius: 10px"
                >Reseller VPN BAL Limit : {{ balancelimit }} USD</i
              >
            </a>
          </li> -->
          <li class="user user-menu">
            <a href="#" @click="logout">
              <i class="fa fa-sign-out"></i>
              <span class="hidden-xs">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AppHeader",
  data() {
    return {
      balancelimit: 0,
      pinlimit: 0,
      userData: {},
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    var balancelimit =
      parseInt(userData.reseller.limit_balance * 10000) / 10000;
    this.pinlimit = userData.reseller.limit_pins;
    this.balancelimit = balancelimit;
  },
  methods: {
    logout(e) {
      loader.hide();
      e.preventDefault();
      let rootObject = this;
      let loginparam = {
        jwt_token: "",
        redirect_url: "login",
      };
      rootObject.com_setLoginData(loginparam);
    },
  },
  computed: {
    ...mapGetters({
      panelData: "getPanelData",
    }),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
