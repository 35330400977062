<template>
  <AddSaleRate />
</template>

<script>
// @ is an alias to /src
import AddSaleRate from "@/components/AddSaleRate.vue";

export default {
  name: "AddSaleRates",
  components: {
    AddSaleRate,
  },
};
</script>
