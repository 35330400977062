<template>
  <div class="content-wrapper" style="background: rgb(237, 240, 242)">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-5">
          <h1>Dashboard</h1>
        </div>
        <div class="col-xs-7 mandatoryDiv">
          <span class="letter-right">
            Last Refresh Time: {{ refresh_datetime }}</span
          >
          <a class="btn btn-sm btn-add" @click="refreshDashboard"
            ><i class="fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="small-box bg-green">
            <div class="inner">
              <h4><span class="fa fa-plus-square"></span> Total VPN Sale</h4>
              <h5
                data-toggle="tooltip"
                title="Total Subscription Sold By Reseller"
              >
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-green"
                  >Resellers Subscription</router-link
                >
                <span class="pull-right" id="ts_rp">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ ts_rp }}</span>
                </span>
              </h5>
              <h5 data-toggle="tooltip" title="Total Subscription Sold By Self">
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-green"
                  >Self Subscription
                  <span id="ts_wp" class="pull-right">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ ts_wp }}</span>
                  </span>
                </router-link>
              </h5>
              <router-link
                :to="{ path: 'pinsubscriptionreport' }"
                class="bg-aqua"
              >
                <h5 class="text-bold">
                  Total
                  <span class="pull-right" id="ts_sum">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ ts_sum }}</span>
                  </span>
                </h5>
              </router-link>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <router-link
              :to="{ path: 'pinsubscriptionreport' }"
              class="small-box-footer"
              >View Detail <i class="fa fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="small-box bg-blue">
            <div class="inner">
              <h4>
                <span class="fa fa-stack-overflow"></span> Self VPN Subscription
                Comparison
              </h4>
              <h5 data-toggle="tooltip" title="Today Self Sale">
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-blue"
                  >Today Now</router-link
                >
                <span id="opc_today" class="pull-right">
                  <span
                    class="fa fa-caret-right"
                    style="font-size: 17px"
                    v-if="self_indicator == 0"
                  ></span>
                  <span
                    class="fa fa-caret-up text-green"
                    style="font-size: 17px"
                    v-if="self_indicator == 1"
                  ></span>
                  <span
                    class="fa fa-caret-down text-red"
                    style="font-size: 17px"
                    v-if="self_indicator == 2"
                  ></span>
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>&nbsp;{{ opc_today }}</span>
                </span>
              </h5>
              <h5 data-toggle="tooltip" title="Yesterday Self Sale">
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-blue"
                  >Yesterday was</router-link
                >
                <span id="opc_yes" class="pull-right">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ opc_yes }}</span>
                </span>
              </h5>
              <router-link
                :to="{ path: 'pinsubscriptionreport' }"
                class="bg-aqua"
              >
                <h5 class="text-bold">
                  Yesterday Total<span id="yestotal" class="pull-right">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ yestotal }}</span>
                  </span>
                </h5>
              </router-link>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <router-link
              :to="{ path: 'pinsubscriptionreport' }"
              class="small-box-footer"
              >View Detail <i class="fa fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="small-box bg-yellow-active">
            <div class="inner">
              <h4>
                <span class="fa fa-line-chart"></span> Reseller VPN Subscription
                Comparison
              </h4>
              <h5 data-toggle="tooltip" title="Today Reseller Sale">
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-yellow-active"
                  >Today Now
                  <span class="pull-right" id="rpc_today">
                    <span
                      class="fa fa-caret-right"
                      style="font-size: 17px"
                      v-if="reseller_indicator == 0"
                    ></span>
                    <span
                      class="fa fa-caret-up text-green"
                      style="font-size: 17px"
                      v-if="reseller_indicator == 1"
                    ></span>
                    <span
                      class="fa fa-caret-down text-red"
                      style="font-size: 17px"
                      v-if="reseller_indicator == 2"
                    ></span>
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>&nbsp; {{ rpc_today }}</span>
                  </span></router-link
                >
              </h5>
              <h5 data-toggle="tooltip" title="Yesterday Reseller Sale">
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-yellow-active"
                  >Yesterday was
                  <span class="pull-right" id="rpc_yes">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ rpc_yes }}</span>
                  </span>
                </router-link>
              </h5>
              <router-link
                :to="{ path: 'pinsubscriptionreport' }"
                class="bg-aqua"
              >
                <h5 class="text-bold">
                  Yesterday Total
                  <span class="pull-right" id="">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ rpc_sum }}</span>
                  </span>
                </h5>
              </router-link>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <router-link
              :to="{ path: 'pinsubscriptionreport' }"
              class="small-box-footer"
              >View Detail <i class="fa fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="small-box bg-purple-active">
            <div class="inner">
              <h4><span class="fa fa-clock-o"></span> Life Time VPN Sales</h4>
              <h5>
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-purple-active"
                  >Total Subscriptions
                  <span class="pull-right" id="life_time_total_pins">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ life_time_total_pins }}</span>
                  </span></router-link
                >
              </h5>
              <h5>
                <router-link
                  :to="{ path: 'pinsubscriptionreport' }"
                  class="bg-purple-active"
                  >Total Price
                  <span class="pull-right" id="life_time_total_pins_price">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span>{{ life_time_total_pins_price }}</span>
                  </span></router-link
                >
              </h5>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <router-link
              :to="{ path: 'pinsubscriptionreport' }"
              class="small-box-footer"
              >View Detail <i class="fa fa-arrow-circle-right"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- /.col -->
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="info-box">
            <span class="info-box-icon bg-yellow"
              ><i class="ion ion-ios-people-outline"></i
            ></span>
            <div class="info-box-content">
              <span class=""> Total Subscribed (VPN) </span>
              <router-link :to="{ path: 'pinsubscriptionreport' }"
                ><span class="info-box-number" id="totSubscribedPin">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ totSubscribedPin }}</span>
                </span>
              </router-link>

              <div v-if="showOnlinePinData">
                <hr style="margin: 0; padding: 0" />
                <a href="" title="Online Subscription">
                  <span> <i class="fa fa-desktop text-success"></i> </span>
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span id="totalOnlineSubscribedPin">
                    {{ totalOnlineSubscribedPin }}
                  </span>
                </a>
              </div>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="info-box">
            <span class="info-box-icon bg-aqua"
              ><i class="fa fa-user-plus"></i
            ></span>
            <div class="info-box-content">
              <span class=""> Today Subscribed (VPN)</span>
              <router-link :to="{ path: 'pinsubscriptionreport' }">
                <span class="info-box-number" id="todSubscribedPin">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ todSubscribedPin }}</span>
                </span>
              </router-link>

              <div v-if="showOnlinePinData">
                <hr style="margin: 0; padding: 0" />
                <a href="" title="Online Subscription">
                  <span> <i class="fa fa-desktop text-success"></i> </span>
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span id="todayOnlineSubscribedPin">
                    {{ todayOnlineSubscribedPin }}
                  </span>
                </a>
              </div>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="info-box">
            <span class="info-box-icon bg-red"
              ><i class="fa fa-credit-card"></i
            ></span>
            <div class="info-box-content">
              <span class="">Total Recharge (VPN)</span>
              <router-link :to="{ path: 'transactionreport' }">
                <span class="info-box-number" id="totRecharge">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ totRecharge }}</span>
                </span>
              </router-link>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <!-- fix for small devices only -->
        <div class="clearfix visible-sm-block"></div>
        <div class="col-md-3 col-sm-12 col-xs-12">
          <div class="info-box">
            <span class="info-box-icon bg-green"
              ><i class="ion ion-ios-cart-outline"></i
            ></span>
            <div class="info-box-content">
              <span class="">Today Recharge (VPN)</span>
              <router-link :to="{ path: 'transactionreport' }">
                <span class="info-box-number" id="todRecharge">
                  <img
                    v-if="loading_data"
                    src="/assets/img/loading_h.gif"
                    alt="Loading"
                  />
                  <span>{{ todRecharge }}</span>
                </span>
              </router-link>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
      </div>

      <!--Subscription Graph Start-->
      <SubscribePinsGraph v-if="renderComponent" />
      <!--Subscription Graph End-->

      <!--Hourly Pin subscription report for last 7 day-->
      <HourlyPinSection v-if="renderComponent" />
      <!--End Hourly Pin subscription report for last 7 day-->

      <!--Secton two start-->
      <div class="row">
        <!--Secton two start-->
        <div class="col-md-4">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Today Top 5 VPN Resellers</h3>
              <div class="box-tools pull-right">
                <a class="fa fa-refresh" @click="loadTopResellerData"></a> |
                <router-link :to="{ path: 'individualsoldpinreport' }">
                  See More</router-link
                >
                <button
                  type="button"
                  class="btn btn-box-tool"
                  data-widget="collapse"
                >
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="box-body">
              <table
                class="table table-0024 table-fixed-height table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th class="tdCenter tdSN">SN</th>
                    <th class="tdCenter">Reseller</th>
                    <th class="tdCenter">Sold Subscriptions</th>
                  </tr>
                </thead>
                <tbody id="get_top_5_reseller_report_bodyid">
                  <tr v-for="(data, index) in topResellerArr" :key="data.id">
                    <td class="tdCenter tdSN">
                      {{ parseInt(++index) }}
                    </td>
                    <td class="success">
                      {{ reseller_list[data.id_reseller + ":" + data.level] }}
                    </td>
                    <td class="tdCenter">{{ data.total_pins }}</td>
                  </tr>
                  <tr v-if="topResellerArr.length == 0 && !tbl1_loading_data">
                    <td colspan="15" class="tdCenter" style="padding: 30px 0">
                      <b>No Records Found</b>
                    </td>
                  </tr>
                  <tr v-if="topResellerArr.length == 0 && tbl1_loading_data">
                    <img src="/assets/img/loading_h.gif" alt="Loading" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Last 5 VPN Recharge</h3>
              <div class="box-tools pull-right">
                <a class="fa fa-refresh" @click="loadRechargeData"></a> |
                <router-link :to="{ path: 'transactionreport' }">
                  See More
                </router-link>
                <button
                  type="button"
                  class="btn btn-box-tool"
                  data-widget="collapse"
                >
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="box-body">
              <table
                class="table table-0024 table-fixed-height table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th class="tdCenter tdSN">SN</th>
                    <th class="tdCenter">Reseller</th>
                    <th class="tdCenter">Date</th>
                    <th class="tdCenter">Amount</th>
                  </tr>
                </thead>
                <tbody id="get_last_5_recharge_report_bodyid">
                  <tr v-for="(data, index) in rechargeArr" :key="data.id">
                    <td class="tdCenter tdSN">
                      {{ parseInt(++index) }}
                    </td>
                    <td class="success">{{ data.login }}</td>
                    <td class="tdCenter">{{ data.creationdate }}</td>
                    <td class="info tdCenter">{{ data.amount }}</td>
                  </tr>
                  <tr v-if="rechargeArr.length == 0 && !loading_data">
                    <td colspan="15" class="tdCenter" style="padding: 30px 0">
                      <b>No Records Found</b>
                    </td>
                  </tr>
                  <tr v-else>
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Last 5 VPN Payments</h3>
              <div class="box-tools pull-right">
                <a class="fa fa-refresh" @click="loadPaymentData"></a> |
                <router-link :to="{ path: 'paymentreport' }">
                  See More</router-link
                >
                <button
                  type="button"
                  class="btn btn-box-tool"
                  data-widget="collapse"
                >
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="box-body">
              <table
                class="table table-0024 table-fixed-height table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th class="tdCenter tdSN">SN</th>
                    <th class="tdCenter">Date</th>
                    <th class="tdCenter">Description</th>
                    <th class="tdCenter">Amount</th>
                  </tr>
                </thead>
                <tbody id="get_last_5_payments_report_bodyid">
                  <tr v-for="(data, index) in paymentArr" :key="data.id">
                    <td class="tdCenter tdSN">
                      {{ parseInt(++index) }}
                    </td>
                    <td class="tdCenter">{{ data.creationdate }}</td>
                    <td class="tdCenter">{{ data.description }}</td>
                    <td class="info tdCenter">{{ data.amount }}</td>
                  </tr>
                  <tr v-if="paymentArr.length == 0 && !loading_data">
                    <td colspan="15" class="tdCenter" style="padding: 30px 0">
                      <b>No Records Found</b>
                    </td>
                  </tr>
                  <tr v-else>
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--Secton two end-->
    </section>
  </div>
</template>
<script>
import axios from "axios";
import HourlyPinSection from "@/components/dashboardComponents/HourlyPinSection.vue";
import SubscribePinsGraph from "@/components/dashboardComponents/SubscribePinsGraph.vue";
export default {
  name: "Dashboard",
  components: {
    HourlyPinSection,
    SubscribePinsGraph,
  },
  data() {
    return {
      renderComponent: true,
      refresh_datetime: "",
      loading_data: true,
      ts_rp: "",
      ts_wp: "",
      ts_sum: "",
      opc_today: "",
      opc_yes: "",
      yestotal: "",
      rpc_today: "",
      rpc_yes: "",
      rpc_sum: "",
      life_time_total_pins: "",
      totSubscribedPin: "",
      todSubscribedPin: "",
      life_time_total_pins_price: "",
      totRecharge: "",
      todRecharge: "",

      totalOnlineSubscribedPin: "",
      todayOnlineSubscribedPin: "",
      showOnlinePinData: false,

      self_indicator: "",
      reseller_indicator: "",

      tbl1_loading_data: true,

      reseller_list: {},
      paymentArr: [],
      rechargeArr: [],
      topResellerArr: [],
    };
  },
  props: {
    msg: String,
  },
  created() {
    this.loadDashboardData();
    this.loadPaymentData();
    this.loadRechargeData();
    this.loadTopResellerData();
    this.showOnlinePinData = this.com_onlineReportAccess();
  },
  methods: {
    loadDashboardData() {
      var rootObj = this;
      loader.show();
      this.refresh_datetime = this.com_currentDateTime("dmy");

      const form = new FormData();
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("dashboard/load-dashboard-pin-count-data", form, config)
        .then(function (response) {
          var dashboardData = response.data.data;
          //console.log(dashboardData);

          rootObj.loading_data = false;
          rootObj.ts_rp =
            parseInt(dashboardData.totalSubscribedPin) -
            parseInt(dashboardData.totalMySubscribedPin);
          rootObj.ts_wp = parseInt(dashboardData.totalMySubscribedPin);
          rootObj.ts_sum = parseInt(dashboardData.totalSubscribedPin);

          if (
            parseInt(dashboardData.todayMySubscribedPin) >
            parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          } else if (
            parseInt(dashboardData.todayMySubscribedPin) <
            parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          } else {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          }

          if (
            parseInt(dashboardData.todaySubscribedPin) -
              parseInt(dashboardData.todayMySubscribedPin) >
            parseInt(dashboardData.yseterdaySubscribedPin) -
              parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          } else if (
            parseInt(dashboardData.todaySubscribedPin) -
              parseInt(dashboardData.todayMySubscribedPin) <
            parseInt(dashboardData.yseterdaySubscribedPin) -
              parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          } else {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          }

          rootObj.opc_yes = dashboardData.yseterdayMySubscribedPin;
          rootObj.yestotal = dashboardData.yseterdayMyTotalSubscribedPin;
          rootObj.rpc_yes =
            dashboardData.yseterdaySubscribedPin -
            dashboardData.yseterdayMySubscribedPin;
          rootObj.rpc_sum =
            dashboardData.yesterdayTotalSubscribedPin -
            dashboardData.yseterdayMyTotalSubscribedPin;
          rootObj.life_time_total_pins = dashboardData.lifetimePinSubscription;
          rootObj.life_time_total_pins_price = parseInt(
            dashboardData.lifetimePinSubscriptionSalesValue
          );
          rootObj.totSubscribedPin = dashboardData.totalSubscribedPin;
          rootObj.todSubscribedPin = dashboardData.todaySubscribedPin;
          rootObj.totRecharge = parseInt(dashboardData.totalRecharge);
          rootObj.todRecharge = parseInt(dashboardData.todayRecharge);
          rootObj.totalOnlineSubscribedPin = parseInt(
            dashboardData.totalOnlineSubscribedPin
          );
          rootObj.todayOnlineSubscribedPin = parseInt(
            dashboardData.todayOnlineSubscribedPin
          );
          rootObj.self_indicator = 0;
          if (rootObj.opc_today > rootObj.opc_yes) {
            rootObj.self_indicator = 1;
          }
          if (rootObj.opc_today < rootObj.opc_yes) {
            rootObj.self_indicator = 2;
          }

          rootObj.reseller_indicator = 0;
          if (rootObj.rpc_today > rootObj.rpc_yes) {
            rootObj.reseller_indicator = 1;
          }
          if (rootObj.rpc_today < rootObj.rpc_yes) {
            rootObj.reseller_indicator = 2;
          }
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObj.loading_data = false;
          loader.hide();
          rootObj.com_errorHandel(error);
        });

      setTimeout(function () {
        loader.hide();
      }, 1000);
    },

    loadPaymentData() {
      var rootObject = this;
      const form = new FormData();
      form.append("noDateFilter", true);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("reports/payment-report", form, config)
        .then(function (response) {
          rootObject.populatePaymentArrData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populatePaymentArrData(response) {
      this.paymentArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        response.data.data[prop]["description"] = response.data.data[prop][
          "description"
        ].substr(0, 10);
        this.paymentArr.push(response.data.data[prop]);
      }
    },

    loadRechargeData() {
      var rootObject = this;
      const form = new FormData();
      form.append("noDateFilter", true);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("reports/transaction-report", form, config)
        .then(function (response) {
          rootObject.populateRechargeArrData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateRechargeArrData(response) {
      //console.log(response);
      this.rechargeArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        this.rechargeArr.push(response.data.data[prop]);
      }
    },

    loadTopResellerData() {
      var rootObject = this;
      this.tbl1_loading_data = true;
      axios
        .get("get-reseller-dropdown-data")
        .then(function (response) {
          rootObject.reseller_list = response.data.data;
          rootObject.tbl1_loading_data = false;
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.tbl1_loading_data = false;
          rootObject.com_errorHandel(error);
        });

      var today = this.com_currentDateTime("", "no");
      const form = new FormData();
      form.append("report_type", 3);
      form.append("fromDate", today);
      form.append("toDate", today);
      form.append("take_rows", 5);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("reports/pin-subscription", form, config)
        .then(function (response) {
          rootObject.populateTopResellerArrData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.com_errorHandel(error);
        });
    },

    populateTopResellerArrData(response) {
      //console.log(response);
      this.topResellerArr = response.data.data;
    },

    refreshDashboard() {
      this.loadDashboardData();
      this.forceRerender();
      this.loadTopResellerData();
      this.loadRechargeData();
      this.loadPaymentData();
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
  },
  mounted: function () {
    //
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
