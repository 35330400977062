<template>
  	<RatePlan />
</template>

<script>

// @ is an alias to /src
import RatePlan from "@/components/RatePlan.vue";

export default {
  name: "RatePlans",
  components: {
    RatePlan,
  },
};
</script>
