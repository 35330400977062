<template>
   <footer class="main-footer">
      <div class="footer-div">
         <span></span>
         Copyright &copy; 2019 - {{(new Date()).getFullYear()}} | Powered by {{panelData.com_name}}. <span class="glyphicon glyphicon-info-sign" data-toggle="modal" data-target="#myModal"></span> <b>V2.0</b>
      </div>
   </footer>
</template>
<script>
   import {mapGetters} from 'vuex'
   export default {
     name: "AppFooter",
     computed: {
       ...mapGetters({
         panelData : 'getPanelData'
       })
     },
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>