<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-7">
          <h1>Reseller Transaction reports</h1>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="searchReportData">
        <div class="row">
          <div class="col-sm-2 col-xs-6">
            <div class="form-group">
              <filterDropdownOptions />
            </div>
          </div>
          <div class="col-sm-2 col-xs-6 form-group">
            <input
              placeholder="From"
              type="text"
              value=""
              name="starttime"
              id="starttime"
              v-model="starttime"
              class="form-control input-sm datePicker"
              autocomplete="off"
            />
          </div>
          <div class="col-sm-2 col-xs-6 form-group">
            <input
              placeholder="To"
              type="text"
              value=""
              name="stoptime"
              id="stoptime"
              v-model="stoptime"
              class="form-control input-sm datePicker"
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="type"
              id="type"
              v-model="type"
              v-select="type"
              class="form-control input-sm select2"
              @change="searchReportData"
            >
              <option value="">Filter Type</option>
              <option value="1">Sell</option>
              <option value="3">Return</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="id_reseller"
              id="id_reseller"
              class="form-control input-sm select2"
              v-model="id_reseller"
              v-select="id_reseller"
              @change="searchReportData"
            >
              <option value="">Filter Reseller</option>
              <option
                v-for="data in resellerArr"
                :key="data.id"
                :value="data.id"
              >
                {{ data.login }}
              </option>
            </select>
          </div>
          <div
            class="col-sm-2 col-xs-6 form-group btnLeft"
            style="margin-bottom: 0"
          >
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">Total {{ listArr.length }} Row Found</h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Reseller Name</th>
                      <th class="tdCenter">Transaction date</th>
                      <th class="tdCenter">Description</th>
                      <th class="tdCenter">Last Balance</th>
                      <th class="tdCenter">TNX Type</th>
                      <th class="tdCenter">No Of Subscription</th>
                      <th class="tdCenter">Recharged</th>
                      <th class="tdCenter">Cash Amount</th>
                      <th class="tdCenter">Row Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in listArr" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(++index) }}
                      </td>
                      <td class="success">{{ data.login }}</td>
                      <td class="tdCenter">{{ data.creationdate }}</td>
                      <td class="tdCenter">{{ data.description }}</td>
                      <td class="tdCenter">
                        {{ parseFloat(data.lastbalance).toFixed(2) }}
                      </td>
                      <td class="tdCenter text-red" v-if="data.type == 3">
                        Returned
                      </td>
                      <td class="tdCenter" v-else>Pre-Paid</td>
                      <td
                        :class="
                          data.type == 3 ? ' tdRight text-red' : 'tdRight'
                        "
                      >
                        <span v-if="data.no_of_pins">
                          {{ data.no_of_pins }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td
                        :class="
                          data.type == 3 ? ' tdRight text-red' : 'tdRight'
                        "
                      >
                        <span v-if="data.recharged_balance">
                          {{ parseFloat(data.recharged_balance).toFixed(2) }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td
                        :class="
                          data.type == 3
                            ? ' info tdRight text-red'
                            : 'info tdRight'
                        "
                      >
                        {{ parseFloat(data.amount).toFixed(2) }}
                      </td>
                      <td class="success tdRight">
                        {{ data.line_total_amount }}
                      </td>
                    </tr>

                    <tr v-if="listArr.length == 0">
                      <td colspan="15" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>

                    <tr>
                      <th colspan="8" class="tdRight">
                        <strong>Total :</strong>
                      </th>
                      <th class="tdRight">
                        <strong> {{ line_total_amount }}</strong>
                      </th>
                      <th class="tdRight">
                        <strong> {{ line_total_amount }}</strong>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-middle-small table-bordered">
                <thead>
                  <tr>
                    <th class="">Total Transaction</th>
                    <th class="tdCenter">Total Cash Received</th>
                    <th class="tdCenter">Total Returned</th>
                    <th class="tdCenter">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="success">{{ listArr.length }}</td>
                    <td class="info">{{ recharged_amount }}</td>
                    <td class="warning">{{ return_amount }}</td>
                    <td class="info">{{ line_total_amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
import axios from "axios";
export default {
  name: "ResellerTransactionReport",
  components: {
    filterDropdownOptions,
  },
  data() {
    return {
      resellerArr: {},
      listArr: {},
      lineTotalAmount: 0,
      type: "",
      id_reseller: "",
      starttime: "",
      stoptime: "",
      line_total_amount: 0,
      recharged_amount: 0,
      return_amount: 0,
    };
  },
  created() {
    var rootObject = this;
    axios
      .get("resellers")
      .then(function (response) {
        rootObject.resellerArr = response.data.resellerData.data;
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        rootObject.com_errorHandel(error);
      });

    this.filterWiseDateRangeSet();
    this.searchReportData();
  },
  methods: {
    searchReportData() {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("type", this.type);
      form.append("fromDate", this.starttime);
      form.append("toDate", this.stoptime);
      form.append("id_reseller", this.id_reseller);
      form.append("short_filter", $("#short_filter").val());

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("reports/transaction-report", form, config)
        .then(function (response) {
          rootObject.populateReportData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateReportData(response) {
      var line_total_amount = 0;
      var recharged_amount = 0;
      var return_amount = 0;
      this.listArr = [];
      this.line_total_amount = 0;
      this.recharged_amount = 0;
      this.return_amount = 0;

      for (var prop in response.data.data) {
        if (response.data.data[prop]["type"] == 3) {
          line_total_amount =
            parseFloat(line_total_amount) -
            parseFloat(response.data.data[prop]["amount"]);
          response.data.data[prop][
            "line_total_amount"
          ] = line_total_amount.toFixed(2);
          return_amount =
            parseFloat(return_amount) +
            parseFloat(response.data.data[prop]["amount"]);
          this.listArr.push(response.data.data[prop]);
        } else {
          line_total_amount =
            parseFloat(line_total_amount) +
            parseFloat(response.data.data[prop]["amount"]);
          response.data.data[prop][
            "line_total_amount"
          ] = line_total_amount.toFixed(2);
          recharged_amount =
            parseFloat(recharged_amount) +
            parseFloat(response.data.data[prop]["amount"]);
          this.listArr.push(response.data.data[prop]);
        }
      }

      this.line_total_amount = line_total_amount.toFixed(2);
      this.return_amount = return_amount.toFixed(2);
      this.recharged_amount = recharged_amount.toFixed(2);
      loader.hide();
    },

    filterWiseDateRangeSet() {
      $(".datePicker").removeAttr("readonly", "readonly");
      if ($("#short_filter").val()) {
        this.starttime = "";
        this.stoptime = "";
        $(".datePicker").attr("readonly", "readonly");
      } else {
        var dateRangeArr = this.com_currentDateRange(30);
        this.starttime = dateRangeArr.startDate;
        this.stoptime = dateRangeArr.endDate;
      }
    },
  },
  mounted: function () {
    var rootObject = this;

    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $("#stoptime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.stoptime = $(this).val();
      });

    $("#short_filter").on("change", function () {
      rootObject.filterWiseDateRangeSet();
      rootObject.searchReportData();
    });

    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
