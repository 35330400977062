<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Add New Reseller Client</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <form role="form" id="formID">
          <div class="col-sm-12">
            <div class="alert alert-info" v-if="error_message">
              {{ error_message }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Basic Configuration</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Reseller Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="full_name"
                      id="full_name"
                      class="form-control input-sm"
                      value=""
                      placeholder="Reseller Name"
                      type="text"
                      v-model="full_name"
                    />
                    <label
                      v-if="error_full_name"
                      class="error"
                      for="full_name"
                      >{{ error_full_name }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Login<span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="login"
                      id="login"
                      class="form-control input-sm"
                      value=""
                      placeholder="Login Username"
                      type="text"
                      v-model="login"
                    />
                    <label v-if="error_login" class="error" for="login">{{
                      error_login
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Password <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="password"
                      id="complex-password"
                      class="form-control input-sm"
                      maxlength="100"
                      value=""
                      placeholder="Login Password"
                      type="text"
                      v-model="password"
                    />
                    <label v-if="error_password" class="error" for="password">{{
                      error_password
                    }}</label>
                    <label class="field_suggestion">
                      1. At least 1 Character, 1 Number, 1 Special Character.<br />
                      2. No space is allowed for password <br />
                      3. Only allowed !@# <br />
                      4. Min Character is 6, Max Character is 17
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Initial Balance
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="balance"
                      id="balance"
                      class="form-control input-sm stop-negative-value"
                      value=""
                      placeholder="Initial Balance"
                      type="number"
                      v-model="balance"
                    />
                    <label v-if="error_balance" class="error" for="balance">{{
                      error_balance
                    }}</label>
                    <label class="field_suggestion"
                      >This amount of money is used to make a call(Reseller OWN
                      call and Subscription call).</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Credit Limit
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="creditLimit"
                      id="creditLimit"
                      class="form-control input-sm stop-negative-value"
                      value=""
                      placeholder="Credit Limit"
                      type="number"
                      v-model="creditLimit"
                    />
                    <label
                      v-if="error_creditLimit"
                      class="error"
                      for="creditLimit"
                      >{{ error_creditLimit }}</label
                    >
                    <label class="field_suggestion"
                      >Postpaid balance [After Initial Balance This amount of
                      money is used to make a call(Reseller OWN call and
                      Subscription call)].</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Rate Plan
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title=""
                      name="rate_plan_id"
                      id="rate_plan_id"
                      class="form-control input-sm select2"
                      v-model="rate_plan_id"
                      v-select="rate_plan_id"
                    >
                      <option value="">Select Plan</option>
                      <option v-for="plan in rate_plans" :value="plan.id">
                        {{ plan.name }}
                      </option>
                    </select>
                    <label
                      v-if="error_rate_plan_id"
                      class="error"
                      for="rate_plan_id"
                      >{{ error_rate_plan_id }}</label
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Is Customize Panel
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title="Select the panel is Customize or not"
                      name="is_custom_panel"
                      id="is_custom_panel"
                      class="form-control input-sm select2"
                      v-model="is_custom_panel"
                      v-select="is_custom_panel"
                    >
                      <option value="">-- Select --</option>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                    <label
                      v-if="error_is_custom_panel"
                      class="error"
                      for="is_custom_panel"
                      >{{ error_is_custom_panel }}</label
                    >
                  </div>
                </div>

                <div
                  class="form-group"
                  id="panelName"
                  v-if="is_custom_panel == 1"
                >
                  <label class="col-md-4 control-label"
                    >Panel Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="custom_panel_name"
                      id="custom_panel_name"
                      class="form-control input-sm"
                      value=""
                      placeholder="Enter Custom Panel Name"
                      type="text"
                      v-model="custom_panel_name"
                    />
                    <label
                      v-if="error_custom_panel_name"
                      class="error"
                      for="custom_panel_name"
                      >{{ error_custom_panel_name }}</label
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label">Active</label>
                  <div class="col-md-8">
                    <input
                      name="status"
                      type="checkbox"
                      id="status"
                      value="1"
                      v-model="status"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">
                  Contact Information
                  <span id="secondButton" class="pull-right showHideButton"
                    ><em>Click to open</em
                    ><i class="fa fa-plus-square button-plus"></i
                  ></span>
                </h3>
              </div>
              <div
                class="box-body form-box-body form-horizontal"
                id="secondShowHide"
              >
                <!-- <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Reseller Limit
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="limit_user"
                      id="limit_user"
                      class="form-control input-sm stop-negative-value"
                      value=""
                      placeholder="Reseller Limit"
                      type="text"
                      v-model="limit_user"
                    />
                    <label
                      v-if="error_limit_user"
                      class="error"
                      for="limit_user"
                      >{{ error_limit_user }}</label
                    >
                    <label class="field_suggestion"
                      >How many <b>Reseller</b> can be created by this
                      Reseller.</label
                    >
                  </div>
                </div> -->
                <!-- <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Balance Limit
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="limit_balance"
                      id="limit_balance"
                      class="form-control input-sm stop-negative-value"
                      value=""
                      placeholder="Balance Limit"
                      type="text"
                      v-model="limit_balance"
                    />
                    <label
                      v-if="error_limit_balance"
                      class="error"
                      for="limit_balance"
                      >{{ error_limit_balance }}</label
                    >
                    <label class="field_suggestion"
                      >This amount of money can be distributed in the
                      <b>Subscription</b>'s created by this Reseller.</label
                    >
                  </div>
                </div> -->
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Email <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="email"
                      id="email"
                      class="form-control input-sm"
                      value=""
                      placeholder="Email"
                      type="text"
                      v-model="email"
                    />
                    <label class="field_suggestion"
                      >Example: abcd@gmail.com</label
                    >
                    <label v-if="error_email" class="error" for="email">{{
                      error_email
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Phone No</label>
                  <div class="col-md-8">
                    <input
                      name="phone"
                      id="phone"
                      class="form-control input-sm"
                      value=""
                      placeholder="Phone No"
                      type="text"
                      v-model="phone"
                    />
                    <label class="field_suggestion">Example: 971XXXXXXX</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">City</label>
                  <div class="col-md-8">
                    <input
                      name="city"
                      id="city"
                      class="form-control input-sm"
                      value=""
                      placeholder="City"
                      type="text"
                      v-model="city"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Country</label>
                  <div class="col-md-8">
                    <select
                      title=""
                      name="country"
                      id="country"
                      class="form-control input-sm select2"
                      v-model="country"
                      v-select="country"
                    >
                      <option value="">Select Country</option>
                      <option
                        v-for="(country, index) in country_arr"
                        :value="index"
                      >
                        {{ country }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">State</label>
                  <div class="col-md-8">
                    <input
                      name="state"
                      id="state"
                      class="form-control input-sm"
                      value=""
                      placeholder="State"
                      type="text"
                      v-model="state"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Address</label>
                  <div class="col-md-8">
                    <textarea
                      name="address"
                      id="address"
                      class="form-control input-sm"
                      rows="3"
                      placeholder="Address"
                      v-model="address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddNewReseller",
  data() {
    return {
      country_arr: {},
      rate_plans: {},
      full_name: "",
      login: "",
      password: "",
      balance: "0.00",
      creditLimit: "0.00",
      rate_plan_id: "",
      status: true,
      is_custom_panel: 0,
      limit_user: 0,
      limit_balance: "0.00",
      email: "",
      phone: "",
      city: "",
      country: "",
      state: "",
      address: "",
      custom_panel_name: "",
      error_message: "",
      is_tv_reseller: "",
      tv_pin_credit_limit: 0,

      error_full_name: false,
      error_login: false,
      error_password: false,
      error_balance: false,
      error_creditLimit: false,
      error_rate_plan_id: false,
      error_limit_user: false,
      error_limit_balance: false,
      error_is_custom_panel: false,
      error_custom_panel_name: false,
      error_email: false,
    };
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_full_name = false;
          rootObject.error_login = false;
          rootObject.error_password = false;
          rootObject.error_balance = false;
          rootObject.error_creditLimit = false;
          rootObject.error_rate_plan_id = false;
          rootObject.error_limit_user = false;
          rootObject.error_limit_balance = false;
          rootObject.error_email = false;
        }, 2000);
        return 0;
      }

      loader.show();
      const form = new FormData();

      let status = 1;
      if (!this.status) {
        status = 0;
      }

      form.append("full_name", this.full_name);
      form.append("login", this.login);
      form.append("password", this.password);
      form.append("balance", this.balance);
      form.append("creditLimit", this.creditLimit);
      form.append("rate_plan_id", this.rate_plan_id);
      form.append("status", status);
      form.append("is_custom_panel", this.is_custom_panel);
      form.append("limit_user", this.limit_user);
      form.append("limit_balance", this.limit_balance);
      form.append("tv_pin_credit_limit", this.tv_pin_credit_limit);
      if (this.is_tv_reseller) {
        form.append("is_tv_reseller", 1);
      } else {
        form.append("is_tv_reseller", 0);
      }

      if (this.email) {
        form.append("email", this.email);
      }
      if (this.phone) {
        form.append("phone", this.phone);
      }
      if (this.city) {
        form.append("city", this.city);
      }
      if (this.country) {
        form.append("country", this.country);
      }
      if (this.state) {
        form.append("state", this.state);
      }
      if (this.address) {
        form.append("address", this.address);
      }
      if (this.custom_panel_name) {
        form.append("custom_panel_name", this.custom_panel_name);
      }

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("resellers", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.error_message = "New Reseller Successfully Created";
          loader.hide();
          setTimeout(function () {
            rootObject.resetForm();
            rootObject.error_message = "";
          }, 2000);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
          rootObject.error_message = alertmessage;
          setTimeout(function () {
            rootObject.error_message = "";
          }, 2000);
        });
    },

    fromValidation(reset = false) {
      var has_error = false;
      this.error_full_name = false;
      this.error_login = false;
      this.error_password = false;
      this.error_balance = false;
      this.error_creditLimit = false;
      this.error_rate_plan_id = false;
      this.error_limit_user = false;
      this.error_limit_balance = false;
      this.error_email = false;

      if (!this.full_name) {
        this.error_full_name = "This field is required";
        has_error = true;
      }

      if (!this.login) {
        this.error_login = "This field is required";
        has_error = true;
      }

      if (!this.password) {
        this.error_password = "This field is required";
        has_error = true;
      }

      if (!this.balance) {
        this.error_balance = "This field is required";
        has_error = true;
      }
      if (!this.creditLimit) {
        this.error_creditLimit = "This field is required";
        has_error = true;
      }
      if (!this.rate_plan_id) {
        this.error_rate_plan_id = "This field is required";
        has_error = true;
      }

      // if (!this.limit_user) {
      //   this.error_limit_user = "This field is required";
      //   has_error = true;
      // }
      // if (!this.limit_balance) {
      //   this.error_limit_balance = "This field is required";
      //   has_error = true;
      // }
      if (!this.email) {
        this.error_email = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    resetForm() {
      var has_error = false;

      this.error_full_name = false;
      this.error_login = false;
      this.error_password = false;
      this.error_balance = false;
      this.error_creditLimit = false;
      this.error_rate_plan_id = false;
      this.error_limit_user = false;
      this.error_limit_balance = false;

      this.full_name = "";
      this.login = "";
      this.password = "";
      this.balance = "";
      this.creditLimit = "";
      this.rate_plan_id = "";
      this.status = "";
      this.is_custom_panel = "";
      this.limit_user = "";
      this.limit_balance = "";
      this.email = "";
      this.phone = "";
      this.city = "";
      this.country = "";
      this.state = "";
      this.address = "";
      this.custom_panel_name = "";
    },
  },
  created() {
    this.limit_balance = "0.00";
    loader.show();
    var rootObject = this;

    var encryptedData = rootObject.$store.getters.getUser;
    var decodedString = rootObject.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    if (userData.user_level == 1) {
      rootObject.$router.push("dashboard");
    }

    axios
      .get("get-countries")
      .then(function (response) {
        rootObject.country_arr = response.data;
      })
      .catch(function (error) {
        let alertmessage = "";
        rootObject.com_errorHandel(error);
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });

    axios
      .get("get-rates-plan")
      .then(function (response) {
        rootObject.rate_plans = response.data;
      })
      .catch(function (error) {
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });
    loader.hide();
  },

  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
