<template>
  	<AddPanelSetting />
</template>

<script>

// @ is an alias to /src
import AddPanelSetting from "@/components/AddPanelSetting.vue";

export default {
  name: "AddPanelSettings",
  components: {
    AddPanelSetting,
  },
};
</script>
