<template>
  	<EditRatePlan />
</template>

<script>

// @ is an alias to /src
import EditRatePlan from "@/components/EditRatePlan.vue";

export default {
  name: "EditRatePlans",
  components: {
    EditRatePlan,
  },
};
</script>
