<template>
  	<TransferLot />
</template>

<script>

// @ is an alias to /src
import TransferLot from "@/components/TransferLot.vue";

export default {
  name: "TransferLots",
  components: {
    TransferLot,
  },
};
</script>
