<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>VPN Rate Plan List</h1>
        </div>
        <div class="col-xs-2">
          <router-link
            :to="{ path: 'addrateplans' }"
            class="btn btn-sm btn-add"
          >
            <i class="fa fa-plus-square"></i>Add New VPN Rate Plan
          </router-link>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">Total row found: {{ rateplans.length }}</h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Rate Plan</th>
                      <th class="tdCenter">Parent</th>
                      <th class="tdCenter">Creation Date</th>
                      <th class="tdCenter">Rate Chart</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in rateplans">
                      <td class="tdCenter tdSN">{{ ++index }}</td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'SaleingRatePlans',
                            params: { id: data.id },
                          }"
                          title="Rate Chart"
                        >
                          {{ data.name }}
                        </router-link>
                      </td>
                      <td class="tdCenter">
                        {{ user_data.login }}
                      </td>
                      <td class="tdCenter">
                        {{ data.created_at }}
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'SaleingRatePlans',
                            params: { id: data.id },
                          }"
                          title="Rate Chart"
                          class="btn btn-sm btn-payment"
                        >
                          <i class="fa fa-th-list"></i>
                          <span style="top: -1px">Rate Plans</span>
                        </router-link>
                      </td>
                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.status == 1"
                          >Active</span
                        >
                        <span class="label label-danger" v-else>Inctive</span>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'EditRatePlans',
                            params: { id: data.id },
                          }"
                          title="Edit Rate Plan"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "RatePlan",
  data() {
    return {
      rateplans: {},
      user_data: {},
    };
  },
  created() {
    loader.show();
    var rootObject = this;

    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.user_data = JSON.parse(decodedString);

    axios
      .get("rate-plan")
      .then(function (response) {
        //console.log(response.data);
        rootObject.rateplans = response.data.batchData.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
