<template>
  	<EditSaleRate />
</template>

<script>

// @ is an alias to /src
import EditSaleRate from "@/components/EditSaleRate.vue";

export default {
  name: "EditSaleRates",
  components: {
    EditSaleRate,
  },
};
</script>
