<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <div class="user-panel">
        <div class="pull-left image">
          <img src="/assets/img/defaultProPic.png" class="img-circle" alt="User Image" />
        </div>
        <div class="pull-left info">
          <p>{{ userData.reseller.full_name }}</p>
          <a href="javascript:;">
            <i class="fa fa-circle text-success"></i> Online
          </a>
          <a class="btn btn-sm btn-add" @click="refreshUserData">
            <b>
              <i class="fa fa-refresh"></i> Refresh
            </b>
          </a>
          <router-link
            :to="{ name: 'PaymentReportView' }"
            style="display: block; margin-top: 10px; color: rgb(189, 30, 44)"
            title="Current Balance"
          >
            <i :class="balance < 50 ? 'top-i text-danger' : 'top-i'">
              <span v-if="balance < 1">VPN Balance(OD):</span>
              <span v-else>Balance:</span>
              {{ balance }} USD
            </i>
          </router-link>
          <p style="font-size: 10px">Avail. Subscription : {{ pinAmount }}</p>
        </div>
      </div>
      <ul class="sidebar-menu">
        <li :class="DashboardActive == true ? 'active' : ''">
          <router-link :to="{ name: 'Home' }">
            <i class="ion ion-ios-speedometer"></i>
            <span>Dashboard</span>
          </router-link>
        </li>

        <li :class="clientActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-user-circle"></i>
            <span>VPN Client</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li v-if="reseller_access">
              <router-link :to="{ name: 'ResellerLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Resellers</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PinLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Single Subscription</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AddPinBatchs' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Generate Subscription Batch</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PinBatchLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Subscription Batch</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ResellerConnectedUsers' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Connected Users</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="rateActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-calculator"></i>
            <span>VPN Rates</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'AddRatePlans' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Add New VPN Rate Plan</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'RatePlans' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Rate Plan</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'BaseRatePlans' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Base Rate Chart</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="reportsActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-line-chart"></i>
            <span>VPN Reports</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'PinSubscriptionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Subscription Subscription Report</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ResellerTransactionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Transaction Report</span>
              </router-link>
            </li>
            <li v-if="allowOnlineReport">
              <router-link :to="{ name: 'OnlineTransactionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Online Transaction Report</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PaymentReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Payment Report</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'IndividualSoldPinReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Individual Sold Subscription</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'HourlySubscriptionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Hourly Subscription</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="settingsActive == true ? 'treeview active' : 'treeview'" v-if="setting_access">
          <a href="#">
            <i class="fa fa-cog"></i>
            <span>Settings</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            `
            <li>
              <router-link :to="{ name: 'AddPanelSettings' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Panel</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  </aside>
</template>
<script>
import axios from "axios";
export default {
  name: "LeftSideBar",
  data() {
    return {
      userData: {},
      balance: 0,
      pinAmount: 0,
      exitsPinPriceAmount: 0,
      setting_access: false,
      reseller_access: false,

      reportsActive: false,
      rateActive: false,
      clientActive: false,
      settingsActive: false,
      DashboardActive: false,
      campaignActive: false,
      allowOnlineReport: false,
    };
  },
  created() {
    var rootObject = this;
    this.activeInactiveSideBar();
    this.allowOnlineReport = this.com_onlineReportAccess();
    this.setting_access = this.com_verifyAllowOrNotSetting();
    this.calculateBalance(this.$store.getters.getUser);
    this.calculatePinAmount();
  },
  methods: {
    calculateBalance(encryptedData) {
      var decodedString = this.com_DecryptString(encryptedData);
      var userData = JSON.parse(decodedString);
      if (userData.user_level != 1) {
        this.reseller_access = true;
      }
      this.userData = userData;
      var balance = parseInt(userData.reseller.balance * 10000) / 10000;
      this.balance = balance;
    },

    calculatePinAmount() {
      var rootObject = this;
      var rateplanid = this.userData.reseller.rate_plan_id;
      axios
        .get("get-rates-by-plan-id/" + rateplanid)
        .then(function (response) {
          for (var prop in response.data.rates) {
            if (response.data.rates[prop]["no_of_days"] == 30) {
              rootObject.pinAmount = parseInt(
                rootObject.balance / response.data.rates[prop]["price"]
              );
              console.log(
                'response.data.rates[prop]["price"] : ',
                response.data.rates[prop]["price"]
              );
            }
          }
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
        });
    },

    refreshUserData() {
      loader.show();
      var rootObject = this;
      //axios.defaults.headers.common['Authorization'] = 'Bearer ' + param.jwt_token;
      axios.get("profile").then(function (response) {
        rootObject.$store.dispatch("setUser", response.data.user);
        rootObject.calculateBalance(response.data.user);
        rootObject.calculatePinAmount();
        loader.hide();
      });
      loader.hide();
    },

    activeInactiveSideBar() {
      let routeName = this.$route.name;
      if (
        routeName == "PinSubscriptionReportView" ||
        routeName == "ResellerTransactionReportView" ||
        routeName == "PaymentReportView" ||
        routeName == "IndividualSoldPinReportView" ||
        routeName == "HourlySubscriptionReportView" ||
        routeName == "CampaignSubscriptionReportView" ||
        routeName == "CampaignSaleReportView" ||
        routeName == "OnlineTransactionReportView"
      ) {
        this.reportsActive = true;
      }

      if (
        routeName == "AddRatePlans" ||
        routeName == "RatePlans" ||
        routeName == "BaseRatePlans" ||
        routeName == "EditRatePlans" ||
        routeName == "SaleingRatePlans" ||
        routeName == "EditSaleRates"
      ) {
        this.rateActive = true;
      }

      if (
        routeName == "ResellerLists" ||
        routeName == "PinLists" ||
        routeName == "AddPinBatchs" ||
        routeName == "PinBatchLists" ||
        routeName == "ResellerConnectedUsers" ||
        routeName == "AddNewResellers" ||
        routeName == "EditResellers" ||
        routeName == "AddSinglePins" ||
        routeName == "EditBatchs" ||
        routeName == "PinListBatchWises"
      ) {
        this.clientActive = true;
      }

      if (routeName == "AddPanelSettings") {
        this.settingsActive = true;
      }

      this.DashboardActive = false;
      if (routeName == "Home") {
        this.DashboardActive = true;
      }

      if (
        routeName == "CampaignLists" ||
        routeName == "AddBillingAccounts" ||
        routeName == "BillingAccountLists" ||
        routeName == "WithdrawLists" ||
        routeName == "AddNewWithdrawals"
      ) {
        this.campaignActive = true;
      }
    },
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.report_section {
  padding-left: 35px;
  font-size: 12px;
  background-color: #cfd3d8;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 5px !important;
  font-weight: bold;
  border-radius: 5px;
}
</style>
