<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-7">
          <h1>Reseller Individual Total Sold Subscription</h1>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="searchReportData">
        <div class="row">
          <div class="col-sm-3 col-xs-6">
            <div class="form-group">
              <filterDropdownOptions />
            </div>
          </div>
          <div class="col-sm-3 col-xs-6 form-group">
            <input
              placeholder="From"
              type="text"
              value=""
              name="starttime"
              id="starttime"
              v-model="starttime"
              class="form-control input-sm datePicker"
              autocomplete="off"
              required=""
            />
          </div>
          <div class="col-sm-3 col-xs-6 form-group">
            <input
              placeholder="To"
              type="text"
              value=""
              name="stoptime"
              id="stoptime"
              v-model="stoptime"
              class="form-control input-sm datePicker"
              autocomplete="off"
              required=""
            />
          </div>
          <div class="col-sm-3 col-xs-16 form-group btnRight" style="">
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 style="display: inline" class="box-title">
                Total {{ listArr.length }} Row Found
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Reseller</th>
                      <th class="tdCenter" width="150">Sold Subscriptions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in listArr" :key="data.id">
                      <td class="tdCenter warning tdSN">
                        {{ parseInt(++index) }}
                      </td>
                      <td class="">
                        {{ reseller_list[data.id_reseller + ":" + data.level] }}
                      </td>
                      <td class="success tdRight">
                        {{ data.total_pins }}
                      </td>
                    </tr>
                    <tr v-if="listArr.length == 0">
                      <td colspan="3" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-middle-small table-bordered">
                <thead>
                  <tr>
                    <th
                      class="tdCenter"
                      style="text-align: center; font-size: 14px"
                      rowspan="2"
                    >
                      Total
                    </th>
                    <th class="tdCenter">Sold Subscriptions</th>
                  </tr>
                  <tr>
                    <th class="success tdRight">{{ totalPins }}</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
import axios from "axios";
export default {
  name: "IndividualSoldPinReport",
  components: {
    filterDropdownOptions,
  },
  data() {
    return {
      listArr: [],
      reseller_list: {},
      lineTotalAmount: 0,
      starttime: "",
      stoptime: "",
      totalPins: 0,
    };
  },
  created() {
    var rootObject = this;
    loader.show();
    axios
      .get("get-reseller-dropdown-data")
      .then(function (response) {
        rootObject.reseller_list = response.data.data;
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        rootObject.com_errorHandel(error);
      });

    this.filterWiseDateRangeSet();
    this.searchReportData();
  },
  methods: {
    searchReportData() {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("report_type", 3);
      form.append("fromDate", this.starttime);
      form.append("toDate", this.stoptime);
      form.append("short_filter", $("#short_filter").val());

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("reports/pin-subscription", form, config)
        .then(function (response) {
          rootObject.populateReportData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateReportData(response) {
      var rootObject = this;
      var totalPins = 0;
      this.listArr = [];
      this.totalPins = 0;

      for (var prop in response.data.data) {
        totalPins =
          parseInt(totalPins) +
          parseInt(response.data.data[prop]["total_pins"]);
      }

      this.totalPins = totalPins;
      this.listArr = response.data.data;
      loader.hide();
    },

    filterWiseDateRangeSet() {
      $(".datePicker").removeAttr("readonly", "readonly");
      if ($("#short_filter").val()) {
        this.starttime = "";
        this.stoptime = "";
        $(".datePicker").attr("readonly", "readonly");
      } else {
        var dateRangeArr = this.com_currentDateRange();
        this.starttime = dateRangeArr.startDate;
        this.stoptime = dateRangeArr.endDate;
      }
    },
  },
  mounted: function () {
    var rootObject = this;

    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $("#stoptime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.stoptime = $(this).val();
      });

    $("#short_filter").on("change", function () {
      rootObject.filterWiseDateRangeSet();
      rootObject.searchReportData();
    });

    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
