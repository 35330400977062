<template>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
           <div class="box">
              <div class="box-header">
                 <h3 class="box-title"> Hourly Subscribed VPN PIN for last 7 days </h3>
                 <div class="box-tools pull-right">
                    <a class="fa fa-refresh" @click="getHourlyPinReport"></a> |   
                       <router-link :to="{path: 'hourlysubscriptionreport'}">
                          See More
                       </router-link>
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                    </button>
                 </div>
              </div>
              <div class="box-body">
                 <div class="table-responsive">
                    <table class="table table-0024  table-bordered table-striped">
                       <thead>
                          <tr>
                             <th class="tdCenter" style="width: 80px!important;">Date</th>
                             <th class="tdCenter">0:00</th>
                             <th class="tdCenter">1:00</th>
                             <th class="tdCenter">2:00</th>
                             <th class="tdCenter">3:00</th>
                             <th class="tdCenter">4:00</th>
                             <th class="tdCenter">5:00</th>
                             <th class="tdCenter">6:00</th>
                             <th class="tdCenter">7:00</th>
                             <th class="tdCenter">8:00</th>
                             <th class="tdCenter">9:00</th>
                             <th class="tdCenter">10:00</th>
                             <th class="tdCenter">11:00</th>
                             <th class="tdCenter">12:00</th>
                             <th class="tdCenter">13:00</th>
                             <th class="tdCenter">14:00</th>
                             <th class="tdCenter">15:00</th>
                             <th class="tdCenter">16:00</th>
                             <th class="tdCenter">17:00</th>
                             <th class="tdCenter">18:00</th>
                             <th class="tdCenter">19:00</th>
                             <th class="tdCenter">20:00</th>
                             <th class="tdCenter">21:00</th>
                             <th class="tdCenter">22:00</th>
                             <th class="tdCenter">23:00</th>
                             <th class="tdCenter tdSN success ">Total</th>
                          </tr>
                       </thead>
                       <tbody id="hourly_pin_report_body">
                          <tr v-if="!hasHourlyPin && !loading_hourly_data">
                             <td colspan="26" class="tdCenter" style="padding: 30px 0;"><b>No Records
                                Found</b>
                             </td>
                          </tr>

                          <tr v-if="!hasHourlyPin && loading_hourly_data">
                             <td colspan="26" style="padding: 5px 0;">
                                <img src="/assets/img/loading_h.gif" alt="Loading"/>
                             </td>
                          </tr>
                          
                          <tr v-for="(data, index) in dateWiseHourlyPinArr" :key="index">
                             <td class="tdCenter">{{index}}</td>
                             <td :class="hourWiseHighestPin[0]==data[0]?'success':''">{{data[0]}}</td>
                             <td :class="hourWiseHighestPin[1]==data[1]?'success':''">{{data[1]}}</td>
                             <td :class="hourWiseHighestPin[2]==data[2]?'success':''">{{data[2]}}</td>
                             <td :class="hourWiseHighestPin[3]==data[3]?'success':''">{{data[3]}}</td>
                             <td :class="hourWiseHighestPin[4]==data[4]?'success':''">{{data[4]}}</td>
                             <td :class="hourWiseHighestPin[5]==data[5]?'success':''">{{data[5]}}</td>
                             <td :class="hourWiseHighestPin[6]==data[6]?'success':''">{{data[6]}}</td>
                             <td :class="hourWiseHighestPin[7]==data[7]?'success':''">{{data[7]}}</td>
                             <td :class="hourWiseHighestPin[8]==data[8]?'success':''">{{data[8]}}</td>
                             <td :class="hourWiseHighestPin[9]==data[9]?'success':''">{{data[9]}}</td>
                             <td :class="hourWiseHighestPin[10]==data[10]?'success':''">{{data[10]}}</td>
                             <td :class="hourWiseHighestPin[11]==data[11]?'success':''">{{data[11]}}</td>
                             <td :class="hourWiseHighestPin[12]==data[12]?'success':''">{{data[12]}}</td>
                             <td :class="hourWiseHighestPin[13]==data[13]?'success':''">{{data[13]}}</td>
                             <td :class="hourWiseHighestPin[14]==data[14]?'success':''">{{data[14]}}</td>
                             <td :class="hourWiseHighestPin[15]==data[15]?'success':''">{{data[15]}}</td>
                             <td :class="hourWiseHighestPin[16]==data[16]?'success':''">{{data[16]}}</td>
                             <td :class="hourWiseHighestPin[17]==data[17]?'success':''">{{data[17]}}</td>
                             <td :class="hourWiseHighestPin[18]==data[18]?'success':''">{{data[18]}}</td>
                             <td :class="hourWiseHighestPin[19]==data[19]?'success':''">{{data[19]}}</td>
                             <td :class="hourWiseHighestPin[20]==data[20]?'success':''">{{data[20]}}</td>
                             <td :class="hourWiseHighestPin[21]==data[21]?'success':''">{{data[21]}}</td>
                             <td :class="hourWiseHighestPin[22]==data[22]?'success':''">{{data[22]}}</td>
                             <td :class="hourWiseHighestPin[23]==data[23]?'success':''">{{data[23]}}</td>
                             <td class="success">{{data['total']}}</td>
                          </tr>
                 
                          <tr class="info">
                             <th style="font-weight: bold; text-align: center;">Total</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[0]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[1]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[2]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[3]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[4]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[5]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[6]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[7]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[8]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[9]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[10]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[11]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[12]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[13]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[14]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[15]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[16]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[17]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[18]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[19]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[20]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[21]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[22]}}</th>
                             <th style="font-weight: bold; text-align: center;">{{hourlyPinArr[23]}}</th>
                             
                             <td style="font-weight: bold; text-align: center;" class="success">
                                {{day_total}}
                             </td>
                          </tr>

                       </tbody>
                    </table>
                 </div>
              </div>
           </div>
        </div>
    </div>
</template>
<script>
	import axios from 'axios'
   	export default {
    	name: "HourlyPinSection",
    	data() {
         	return {
	            hasHourlyPin : false,
	            loading_hourly_data : true,
	            starttime : '',
	            stoptime : '',
	            hourlyPinArr : {},
	            hourWiseHighestPin : {},
	            dateWiseHourlyPinArr : [],
	            starttime : '',
	            stoptime : '',
	            day_total : 0,
	        }
	    },
	    created(){
         	this.getHourlyPinReport();
      	},
	    methods : {
	    	// Start Hourly Pin Methods 
	         getHourlyPinReport(){
	            var rootObject = this;
	            this.dateWiseHourlyPinArr = [];
	            this.hasHourlyPin = false;
	            this.loading_hourly_data = true;
	            this.day_total = 0;
	            this.defineDefaultHours();
	            var dateRangeArr = this.com_currentDateRange(-7);
	            this.starttime = dateRangeArr.startDate;
	            this.stoptime = dateRangeArr.endDate;
	            setTimeout(function(){
	               rootObject.searchHourlyPinData();
	            }, 1000)
	         },

	         searchHourlyPinData(){
	            var rootObject = this;
	            const form = new FormData()
	            form.append('id_reseller', '')
	            form.append('fromDate', this.starttime)
	            form.append('toDate', this.stoptime)
	            form.append('short_filter', '');
	   
	            var config = {
	             headers: { 'Content-Type': 'multipart/form-data' }
	            }
	   
	            axios.post('reports/pin-subscription', form, config).then(function (response) {
	               rootObject.populateHourlyPinReportData(response);
	            })
	            .catch(function (error) {
	                let alertmessage = "";
	                for (var prop in error.response.data) {
	                  alertmessage += ' '+error.response.data[prop]
	                }
	                console.log(alertmessage);
	                loader.hide();
	                rootObject.com_errorHandel(error);
	            });
	         },
	   
	         populateHourlyPinReportData(response){
	            var rootObject = this;
	            var modifiedArr = {};
	            var hourArr = {};
	            var dateArr = {};
	            var hourlyPinArr = this.hourlyPinArr;
	            var hourWiseHighestPin = this.hourWiseHighestPin;
	            var date = '';
	            var hour = [];
	            var day_total = 0;

	            for (var prop in response.data.data) {
	               rootObject.hasHourlyPin = true;
	               dateArr = rootObject.com_displayDateFormat(response.data.data[prop]['creationdate']);
	               date = dateArr.date;
	               hour = dateArr.hour;
	               hourArr = [];

	               if(modifiedArr[date] == undefined) {
	                  hourArr[hour] = 1;
	                  hourArr['total'] = 1;
	                  modifiedArr[date] = hourArr;

	                  if(hourArr[hour] > hourWiseHighestPin[hour]){
	                     hourWiseHighestPin[hour] = hourArr[hour];
	                  }

	               }else{
	                  hourArr = modifiedArr[date]
	                  if(hourArr[hour] == undefined) {
	                     hourArr[hour] = 1;
	                  }else{
	                     hourArr[hour] = hourArr[hour]+1;
	                  }
	                  hourArr['total'] = hourArr['total'] + 1;
	                  modifiedArr[date] = hourArr;

	                  if(hourArr[hour] > hourWiseHighestPin[hour]){
	                     hourWiseHighestPin[hour] = hourArr[hour];
	                  }
	               }
	               hourlyPinArr[hour] = parseInt(hourlyPinArr[hour]) + 1;
	               day_total++;
	            }

	            //console.log(modifiedArr);
	            this.day_total = day_total;
	            this.dateWiseHourlyPinArr = modifiedArr;
	            this.hourlyPinArr = hourlyPinArr;
	            this.hourWiseHighestPin = hourWiseHighestPin;
	            this.loading_hourly_data = false;
	            //console.log(hourWiseHighestPin);
	         },

	         defineDefaultHours(){
	            var hourlyPinArr = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
	            var hourWiseHighestPin = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
	            this.hourlyPinArr = hourlyPinArr; 
	            this.hourWiseHighestPin = hourWiseHighestPin; 
	         }

	         // End Hourly Pin Methods
	    }

   	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>