<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>
            Transfer PIN Batch :<span class="text-blue">{{ description }}</span>
          </h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <form>
          <div class="col-sm-12">
            <div class="alert alert-info" v-if="alert_message">
              {{ alert_message }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Transfer Information</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Reseller to transfer
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title=""
                      name="reseller_id"
                      id="reseller_id"
                      class="form-control input-sm"
                      onfocus="this.removeAttribute('readonly');"
                      v-model="reseller_id"
                      readonly=""
                      @change="loadBundle"
                    >
                      <option value="">All reseller</option>
                      <option
                        v-for="(data, index) in reseller_list"
                        :key="index"
                        :value="index"
                      >
                        {{ data }}
                      </option>
                    </select>
                    <label
                      v-if="error_reseller_id"
                      class="error"
                      for="reseller_id"
                      >{{ error_reseller_id }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Batch Name </label>
                  <div class="col-md-8">
                    <input
                      name="description"
                      id="description"
                      class="form-control input-sm valid"
                      value=""
                      placeholder="Batch Name (Optional)"
                      type="text"
                      onfocus="this.removeAttribute('readonly');"
                      aria-required="true"
                      aria-invalid="false"
                      v-model="description"
                    />
                    <label
                      v-if="error_description"
                      class="error"
                      for="description"
                      >{{ error_description }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Bundle <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title=""
                      name="rate_plan_id"
                      id="rateBundleId"
                      class="form-control input-sm"
                      onfocus="this.removeAttribute('readonly');"
                      v-model="rate_plan_id"
                      @change="loadRatePlans"
                    >
                      <option value="">Select Bundle</option>
                      <option
                        v-for="data in bundles"
                        :key="data.id"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                    <label
                      v-if="error_rate_plan_id"
                      class="error"
                      for="rate_plan_id"
                      >{{ error_rate_plan_id }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Rate Plan<span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title=""
                      name="plan_id"
                      id="plan_id"
                      class="form-control input-sm"
                      onfocus="this.removeAttribute('readonly');"
                      readonly=""
                      v-model="plan_id"
                    >
                      <option value="">Select Rate Plan</option>
                      <option
                        v-for="data in rateplans"
                        :key="data.id"
                        :value="data.id"
                      >
                        {{ data.plan_name }}
                      </option>
                    </select>
                    <label v-if="error_plan_id" class="error" for="plan_id">{{
                      error_plan_id
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Transfer Now"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TransferLot",
  data() {
    return {
      description: "",
      bundles: {},
      rateplans: {},
      reseller_list: {},
      rate_plan_id: "",
      plan_id: "",
      reseller_id: "",
      alert_message: "",
      batch_id: 0,

      error_reseller_id: false,
      error_description: false,
      error_rate_plan_id: false,
      error_plan_id: false,
    };
  },
  created() {
    loader.show();
    var batch_id = this.$route.params.id;
    this.batch_id = batch_id;
    var rootObject = this;

    axios
      .get("batches/" + batch_id)
      .then(function (response) {
        rootObject.description = response.data.data.description;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        rootObject.alert_message = alertmessage;
        loader.hide();
        rootObject.com_errorHandel(error);
      });

    axios
      .get("get-reseller-dropdown-data")
      .then(function (response) {
        rootObject.reseller_list = response.data.data;
        //console.log(rootObject.reseller_list);
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        return 0;
      }

      loader.show();

      const form = new FormData();
      form.append("reseller_id", this.reseller_id);
      form.append("description", this.description);
      form.append("rate_plan_id", this.rate_plan_id);
      form.append("plan_id", this.plan_id);
      form.append("lot_id", this.batch_id);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("batch-pin-transfer", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = "Batch Transfered Successful";
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    loadBundle() {
      var rootObject = this;
      rootObject.bundles = {};
      rootObject.rateplans = {};
      rootObject.rate_plan_id = "";
      rootObject.plan_id = "";
      loader.show();
      axios
        .get("rate-plan-dropdown-data?id_reseller=" + this.reseller_id)
        .then(function (response) {
          //console.log(response)
          rootObject.bundles = response.data;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    loadRatePlans() {
      var rootObject = this;
      rootObject.rateplans = {};
      rootObject.plan_id = "";
      loader.show();
      axios
        .get("get-rates-by-plan-id/" + this.rate_plan_id)
        .then(function (response) {
          //console.log(response)
          rootObject.rateplans = response.data.rates;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.reseller_id) {
        this.error_reseller_id = "This field is required";
        has_error = true;
      }
      if (!this.description) {
        this.error_description = "This field is required";
        has_error = true;
      }
      if (!this.rate_plan_id) {
        this.error_rate_plan_id = "This field is required";
        has_error = true;
      }
      if (!this.plan_id) {
        this.error_plan_id = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
