<template>
  	<OnlineTransactionReport />
</template>

<script>

// @ is an alias to /src
import OnlineTransactionReport from "@/components/reports/OnlineTransactionReport.vue";

export default {
  name: "OnlineTransactionReportView",
  components: {
    OnlineTransactionReport,
  },
};
</script>
