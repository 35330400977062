<template>
  	<PinList />
</template>

<script>

// @ is an alias to /src
import PinList from "@/components/PinList.vue";

export default {
  name: "PinLists",
  components: {
    PinList,
  },
};
</script>
