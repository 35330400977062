<template>
  <div id="app" @mousedown="com_SetLastActivityTime">
    <AppHeader v-if="loginStatus" />
    <LeftSideBar v-if="loginStatus" />
    <router-view />
    <AppFooter v-if="loginStatus" />
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from "@/components/AppHeader.vue";
import LeftSideBar from "@/components/LeftSideBar.vue";
import AppFooter from "@/components/AppFooter.vue";

import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    AppHeader,
    LeftSideBar,
    AppFooter,
  },
  created() {
    var api_public_path = process.env.VUE_APP_APIRESELLERPUBLIC;
    var rootObject = this;
    //Check Panel Param Exists or not
    var panel = this.$route.params.panel;
    if (panel == undefined) {
      rootObject.com_pageNotFound();
    } else {
      if (this.$store.getters.getLoginStatus) {
        axios.defaults.headers.common["panel"] = panel;
      }
      //Get Panel Configurations
      axios
        .get("panel-setting", {
          params: {
            panel_name: panel,
          },
        })
        .then(function (response) {
          //console.log(response.data);
          if (response.data.settings == null) {
            rootObject.com_pageNotFound();
          } else {
            let panelparam = {
              panel: panel,
              panel_settings: response.data.settings,
            };
            window.document.title =
              "Admin Panel - " + response.data.settings.com_name.toUpperCase();
            //$("#favicon").attr('href', response.data.settings.favicon);
            if (response.data.settings.favicon) {
              $("#favicon").attr(
                "href",
                api_public_path +
                  "uploads/images/" +
                  response.data.settings.favicon
              );
            } else {
              $("#favicon").attr("href", "/assets/img/symlex_logo.png");
            }
            rootObject.com_setPanelData(panelparam);
          }
        })
        .catch(function (error) {
          /*
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += ' '+error.response.data[prop]
          }
          console.log(alertmessage);
        */
          rootObject.com_pageNotFound();
        });
    }
  },
  methods: {
    setLastActivityTime(event) {
      console.log(event.clientX, event.clientY);
      //var current = new Date();
      //localStorage.setItem('lastActivityTime', current.getTime());
    },
  },
  computed: {
    ...mapGetters({
      loginStatus: "getLoginStatus",
    }),
  },
  methods: {},
};
</script>

<style>
.content-wrapper {
  min-height: 97.2vh !important;
  max-height: 100% !important;
}
</style>
