<template>
  	<HourlySubscriptionReport />
</template>

<script>

// @ is an alias to /src
import HourlySubscriptionReport from "@/components/reports/HourlySubscriptionReport.vue";

export default {
  name: "HourlySubscriptionReportView",
  components: {
    HourlySubscriptionReport,
  },
};
</script>
