<template>
  	<ResellerTransactionReport />
</template>

<script>

// @ is an alias to /src
import ResellerTransactionReport from "@/components/reports/ResellerTransactionReport.vue";

export default {
  name: "ResellerTransactionReportView",
  components: {
    ResellerTransactionReport,
  },
};
</script>
