<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-12">
          <h1 class="pull-left">Online Transaction Reports</h1>
          <div class="pull-right"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="searchReportData">
        <div class="row">
          <div class="col-xs-6 col-sm-2">
            <div class="form-group">
              <filterDropdownOptions />
            </div>
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="From"
              type="text"
              value=""
              name="starttime"
              id="starttime"
              v-model="starttime"
              autocomplete="off"
              class="form-control input-sm datePicker"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="To"
              type="text"
              value=""
              name="stoptime"
              id="stoptime"
              v-model="stoptime"
              class="form-control input-sm datePicker"
              autocomplete="off"
            />
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="payment_gate_way"
              id="payment_gate_way"
              class="form-control input-sm select2"
              v-model="payment_gate_way"
              v-select="payment_gate_way"
              @change="searchReportData"
            >
              <option value="">All Payment Gateway</option>
              <option value="1">SSL Payment All</option>
              <option value="9">SSL Payment (Android)</option>
              <option value="10">SSL Payment (Site)</option>
              <option value="2">PayPal Payment</option>
              <option value="3">In-App Purchase All</option>
              <option value="4">In-App Purchase iOS</option>
              <option value="5">In-App Purchase Android</option>
              <option value="6">In-App Purchase Amazon</option>
              <option value="8">In-App Purchase Huawei</option>
              <option value="11">Stripe Payment</option>
              <option value="7">Offline Payment</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="platform"
              id="platform"
              class="form-control input-sm select2"
              v-model="platform"
              v-select="platform"
              @change="searchReportData"
            >
              <option value="">All Platform</option>
              <option value="1">Website</option>
              <option value="2">Android</option>
              <option value="3">IOS</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="tran_status"
              id="tran_status"
              class="form-control input-sm select2"
              v-model="tran_status"
              v-select="tran_status"
              @change="searchReportData"
            >
              <option value="">All Transaction</option>
              <option value="Success">Success</option>
              <option value="Pending">Pending</option>
              <option value="Canceled">Canceled</option>
              <option value="Failed">Failed</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              type="text"
              name="email"
              value=""
              id="search_email"
              placeholder="Search By Customer Email"
              class="form-control input-sm"
              v-model="search_email"
            />
          </div>
          <div class="col-sm-2 col-xs-6 form-group" style="">
            <select
              title=""
              name="isRenewalCount"
              id="isRenewalCount"
              class="form-control input-sm select2"
              v-model="isRenewalCount"
              v-select="isRenewalCount"
              @change="searchReportData"
            >
              <option value="0">Without Renewal Count</option>
              <option value="1">With Renewal Count</option>
            </select>
          </div>
          <div
            id="btnSearch"
            class="col-xs-6 col-sm-2 pull-right form-group btnLeft"
            style="margin-bottom: 5px"
          >
            <button type="submit" class="btn btn-sm btn-submit pull-right">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">Total {{ listArr.length }} Row Found</h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Username</th>
                      <th class="tdCenter">Customer Email</th>
                      <th class="tdCenter">Name</th>
                      <th class="tdCenter">Customer Country</th>
                      <th class="tdCenter">Transaction Date</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Gateway</th>
                      <th class="tdCenter">Platform</th>
                      <th class="tdCenter">Plan</th>
                      <th class="tdCenter">Logged In</th>
                      <th class="tdCenter">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in listArr"
                      :key="data.id + data.created_at"
                    >
                      <td class="tdCenter tdSN warning">
                        {{ parseInt(++index) }}
                      </td>
                      <td class="tdLeft">
                        <a @click="searchReportData(1, data.username)">
                          {{
                            com_removeHostedPinPrefix(
                              data.username,
                              userData.reseller4.hosted_pin_prefix,
                              userData.reseller4.reseller_panel_type
                            )
                          }}
                          <span
                            class="label label-warning"
                            v-if="data.pinRenewalCount > 0"
                          >
                            {{ data.pinRenewalCount }}
                          </span>
                        </a>
                      </td>
                      <td class="tdLeft">
                        <span
                          v-if="
                            data.gate_way == 'PayPal' &&
                            data.tran_status != 'approved'
                          "
                        >
                          <a
                            v-if="data.cus_email"
                            @click="searchReportData(2, data.cus_email)"
                          >
                            {{
                              com_removeHostedPinPrefix(
                                data.cus_email,
                                userData.reseller4.hosted_pin_prefix,
                                userData.reseller4.reseller_panel_type
                              )
                            }}
                          </a>
                        </span>
                        <span v-else>
                          <a
                            v-if="data.payer_email"
                            @click="searchReportData(3, data.payer_email)"
                          >
                            {{
                              com_removeHostedPinPrefix(
                                data.payer_email,
                                userData.reseller4.hosted_pin_prefix,
                                userData.reseller4.reseller_panel_type
                              )
                            }}
                          </a>
                        </span>
                      </td>
                      <td class="tdLeft">
                        <span v-if="data.cus_name">
                          {{
                            com_removeHostedPinPrefix(
                              data.cus_name,
                              userData.reseller4.hosted_pin_prefix,
                              userData.reseller4.reseller_panel_type
                            )
                          }}
                        </span>
                      </td>
                      <td class="tdCenter">
                        {{ data.country }}
                      </td>
                      <td class="tdCenter">
                        {{ data.created_at }}
                      </td>
                      <td class="tdCenter">
                        {{ data.tran_status }}
                      </td>

                      <!-- Section One Start -->
                      <td
                        class="tdCenter bg-blue-gradient"
                        v-if="data.gate_way == 'SSL'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter"
                        style="background-color: #6058f7; color: #fff8f8"
                        v-else-if="data.gate_way == 'Stripe'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter bg-aqua-gradient"
                        v-else-if="data.gate_way == 'PayPal'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter bg-green-gradient"
                        v-else-if="data.gate_way == 'Android'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter bg-gray-active"
                        v-else-if="data.gate_way == 'iOS'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter bg-black-active"
                        v-else-if="data.gate_way == 'Amazon'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td
                        class="tdCenter bg-maroon"
                        v-else-if="data.gate_way == 'Huawei'"
                      >
                        {{ data.gate_way }}
                      </td>
                      <td class="tdCenter" v-else></td>
                      <!-- Section One End -->

                      <td class="tdCenter">
                        <span v-if="data.gate_way == 'iOS'"> IOS </span>
                        <span
                          v-else-if="
                            data.gate_way == 'SSL' && data.payment_from == '1'
                          "
                        >
                          Site
                        </span>
                        <span v-else> Android </span>
                      </td>

                      <td class="tdCenter">
                        {{ data.plan_name }}
                      </td>

                      <td class="tdCenter">
                        {{ data.loggedin }}
                      </td>

                      <td class="tdRight success">
                        {{ data.total_amount }}
                      </td>
                    </tr>
                    <tr v-if="listArr.length == 0">
                      <td colspan="12" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-middle-small table-bordered">
                <thead>
                  <tr>
                    <th class="tdCenter" rowspan="2">Total</th>
                    <th class="tdCenter">Transaction</th>
                    <th class="tdCenter">Amount</th>
                  </tr>
                  <tr>
                    <th class="warning">{{ listArr.length }}</th>
                    <th class="success">
                      {{ total_amount }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
import axios from "axios";
export default {
  name: "OnlineTransactionReport",
  components: {
    filterDropdownOptions,
  },
  data() {
    return {
      listArr: [],
      userData: {},
      starttime: "",
      stoptime: "",
      userlevel: "",

      search_email: "",
      payment_gate_way: "",
      platform: "",
      tran_status: "",
      isRenewalCount: 1,
      total_amount: 0,
    };
  },
  created() {
    //Get User Level from user store
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    this.userlevel = userData.user_level;
    // End

    var onlineReplortAccess = this.com_onlineReportAccess();
    if (!onlineReplortAccess) {
      this.$router.push("dashboard");
    }

    var rootObject = this;
    loader.show();
    this.filterWiseDateRangeSet();
    this.searchReportData();
  },
  methods: {
    searchReportData(type = false, value = false) {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("search_email", this.search_email);
      form.append("fromDate", this.starttime);
      form.append("toDate", this.stoptime);
      form.append("payment_gate_way", this.payment_gate_way);
      form.append("platform", this.platform);
      form.append("tran_status", this.tran_status);
      form.append("isRenewalCount", this.isRenewalCount);
      form.append("short_filter", $("#short_filter").val());

      if (type == 1) {
        form.append("username", value);
      }
      if (type == 2) {
        form.append("cus_email", value);
      }
      if (type == 3) {
        form.append("cus_email", value);
      }

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("reports/web-transaction-report", form, config)
        .then(function (response) {
          //rootObject.listArr = response.data.data.pinPayments;
          //console.log(response);
          rootObject.populateReportData(response);
          //loader.hide();
        })
        .catch(function (error) {
          console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateReportData(response) {
      var rootObject = this;
      var total_amount = 0;
      this.listArr = [];
      this.total_amount = 0;

      var pinRenewalCount = response.data.data.pinRenewalCount;
      //var childArr = response.data.data.childArr;
      for (var prop in response.data.data.pinPayments) {
        total_amount += parseFloat(
          response.data.data.pinPayments[prop]["total_amount"]
        );

        var gate_way = response.data.data.pinPayments[prop]["gate_way"];
        var id = response.data.data.pinPayments[prop]["id"];
        response.data.data.pinPayments[prop]["pinRenewalCount"] = 0;
        if (pinRenewalCount[gate_way] && pinRenewalCount[gate_way][id]) {
          response.data.data.pinPayments[prop]["pinRenewalCount"] =
            pinRenewalCount[gate_way][id];
        }

        this.listArr.push(response.data.data.pinPayments[prop]);
      }
      this.total_amount = total_amount.toFixed(2);
      loader.hide();
    },

    filterWiseDateRangeSet() {
      $(".datePicker").removeAttr("readonly", "readonly");
      if ($("#short_filter").val()) {
        this.starttime = "";
        this.stoptime = "";
        $(".datePicker").attr("readonly", "readonly");
      } else {
        var dateRangeArr = this.com_currentDateRange();
        this.starttime = dateRangeArr.startDate;
        this.stoptime = dateRangeArr.endDate;
      }
    },
  },
  mounted: function () {
    var rootObject = this;
    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $("#stoptime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.stoptime = $(this).val();
      });

    $("#short_filter").on("change", function () {
      rootObject.filterWiseDateRangeSet();
      rootObject.searchReportData();
    });

    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
