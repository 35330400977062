<template>
  	<PinBatchList />
</template>

<script>

// @ is an alias to /src
import PinBatchList from "@/components/PinBatchList.vue";

export default {
  name: "PinBatchLists",
  components: {
    PinBatchList,
  },
};
</script>
