<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Add New Single Subscription</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-sm-7">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Basic Configuration</h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <!-- This Section is for without prefix username -->
                <div>
                  <div v-if="userData.reseller4.reseller_type == 4">
                    <label class="col-md-4 control-label"
                      >Subscription
                      <span class="mandatory_horizontal">*</span></label
                    >
                    <div class="col-md-8" v-if="!prefix">
                      <input
                        name="username"
                        id="username"
                        class="form-control input-sm"
                        value=""
                        v-model="username"
                        placeholder="Email"
                        type="email"
                        onfocus="this.removeAttribute('readonly');"
                        @blur="isEmailValid"
                        readonly=""
                      />
                      <label
                        v-if="error_username"
                        class="error"
                        for="username"
                        >{{ error_username }}</label
                      >
                      <label class="field_suggestion">
                        * Please enter valid email address
                      </label>
                    </div>
                  </div>
                  <div v-else>
                    <label class="col-md-4 control-label"
                      >Subscription
                      <span class="mandatory_horizontal">*</span></label
                    >
                    <div class="col-md-8" v-if="!prefix">
                      <input
                        name="username"
                        id="username"
                        class="form-control input-sm stop-specialchar"
                        value=""
                        v-model="username"
                        placeholder="Subscription"
                        type="text"
                        onfocus="this.removeAttribute('readonly');"
                        @keyup="checkLeadingZero(1)"
                        readonly=""
                      />
                      <label
                        v-if="error_username"
                        class="error"
                        for="username"
                        >{{ error_username }}</label
                      >
                      <label class="field_suggestion">
                        * Don't use Zero (0) as a first letter
                      </label>
                    </div>
                  </div>
                </div>

                <!-- This Section is for with prefix username -->
                <div>
                  <div class="col-md-2" v-if="prefix">
                    <input
                      class="form-control input-sm"
                      value=""
                      placeholder="Prefix"
                      type="text"
                      disabled
                      readonly
                      v-model="prefix"
                    />
                    <label class="field_suggestion"> Prefix </label>
                  </div>
                  <div class="col-md-6" v-if="prefix">
                    <input
                      name="username"
                      id="username"
                      class="form-control input-sm stop-specialchar"
                      value=""
                      v-model="username"
                      placeholder="Subscription"
                      type="text"
                      onfocus="this.removeAttribute('readonly');"
                      @keyup="checkLeadingZero(1)"
                      readonly=""
                    />
                    <label v-if="error_username" class="error" for="username">{{
                      error_username
                    }}</label>
                    <label class="field_suggestion">
                      * Don't use Zero (0) as a first letter
                    </label>
                  </div>
                </div>
              </div>

              <div
                :class="
                  userData.reseller4.is_single_pin_login == 1
                    ? 'form-group displayhide'
                    : 'form-group'
                "
              >
                <label class="col-md-4 control-label"
                  >Subscription Password
                  <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <input
                    name="password"
                    id="password"
                    class="form-control input-sm stop-negative-value"
                    v-model="password"
                    placeholder="Subscription Password"
                    type="number"
                    onfocus="this.removeAttribute('readonly');"
                    @keyup="checkLeadingZero(2)"
                    readonly=""
                  />
                  <label v-if="error_password" class="error" for="password">{{
                    error_password
                  }}</label>
                  <label class="field_suggestion">
                    * User only number and Don't use Zero (0) as a first letter
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Bundle <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <select
                    title=""
                    name="rate_plan_id"
                    v-model="rate_plan_id"
                    v-select="rate_plan_id"
                    id="rate_plan_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    readonly=""
                    @change="loadRatePlans"
                  >
                    <option value="">Select Bundle</option>
                    <option
                      v-for="data in bundles"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <label
                    v-if="error_rate_plan_id"
                    class="error"
                    for="rate_plan_id"
                    >{{ error_rate_plan_id }}</label
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Rate Plan<span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <select
                    title=""
                    name="plan_id"
                    v-model="plan_id"
                    v-select="plan_id"
                    id="plan_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    readonly=""
                  >
                    <option value="">Select Rate Plan</option>
                    <option
                      v-for="data in rateplans"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.plan_name }}
                    </option>
                  </select>
                  <label v-if="error_plan_id" class="error" for="plan_id">{{
                    error_plan_id
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 btnCenter">
          <input
            type="submit"
            name="submit"
            value="Generate"
            class="btn btn-sm btn-submit btn-submit-big"
            @click="formSubmit"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddSinglePin",
  data() {
    return {
      userData: {},
      bundles: {},
      rateplans: {},
      prefix: "",
      username: "",
      rate_plan_id: "",
      plan_id: "",
      password: "",
      alert_message: "",

      error_username: false,
      error_password: false,
      error_rate_plan_id: false,
      error_plan_id: false,
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);
    this.prefix = this.userData.reseller4.branding_pin_prefix;

    this.password = Math.floor(Math.random() * 999999 + 100000);
    var rootObject = this;
    loader.show();
    axios
      .get("rate-plan?status=1")
      .then(function (response) {
        rootObject.bundles = response.data.batchData.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_username = false;
          rootObject.error_password = false;
          rootObject.error_rate_plan_id = false;
          rootObject.error_plan_id = false;
        }, 5000);

        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("username", this.username);
      form.append("rate_plan_id", this.rate_plan_id);
      form.append("plan_id", this.plan_id);
      form.append("value", this.password);
      form.append("password", this.password);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("pins", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = "New Subscription Successfully Created";
          rootObject.username = "";
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });
      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_username = false;
        rootObject.error_password = false;
        rootObject.error_rate_plan_id = false;
        rootObject.error_plan_id = false;
      }, 5000);
    },

    loadRatePlans() {
      var rootObject = this;
      if (this.rate_plan_id) {
        loader.show();

        axios
          .get("get-rates-by-plan-id/" + this.rate_plan_id)
          .then(function (response) {
            //console.log(response)
            rootObject.rateplans = response.data.rates;
            rootObject.plan_id = "";
            loader.hide();
          })
          .catch(function (error) {
            let alertmessage = "";
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
            console.log(alertmessage);
            loader.hide();
            rootObject.com_errorHandel(error);
          });
      } else {
        rootObject.rateplans = {};
        rootObject.plan_id = "";
      }
    },

    checkLeadingZero(field_no) {
      var val = "";
      if (field_no == 1) {
        val = this.username;
      }

      if (field_no == 2) {
        val = this.password;
      }
      var firstDigits = ("" + val).split("")[0];
      if (firstDigits == "0") {
        $.alert("Please, Do not use Zero (0) as a first letter for this field");
        if (field_no == 1) {
          this.username = "";
        }
        if (field_no == 2) {
          this.password = "";
        }
      }
    },

    isEmailValid: function () {
      var email = this.username;
      if (email != "") {
        //if (email.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) == false
        ) {
          this.error_username = "Invalid email address.";
          return false;
        }
      }
      this.error_username = false;
      return true;
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.username) {
        this.error_username = "This field is required";
        has_error = true;
      }

      if (!this.password) {
        this.error_password = "This field is required";
        has_error = true;
      }

      if (!this.rate_plan_id) {
        this.error_rate_plan_id = "This field is required";
        has_error = true;
      }

      if (!this.plan_id) {
        this.error_plan_id = "This field is required";
        has_error = true;
      }

      if (this.userData.reseller4.reseller_type == 4) {
        var IsValid = this.isEmailValid();
        if (!IsValid) {
          has_error = true;
        }
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });

    $(".stop-specialchar").keypress(function (e) {
      var keyCode = e.keyCode || e.which;
      var regexp = new RegExp("^[a-zA-Z0-9]+$");
      var result = regexp.test(String.fromCharCode(keyCode));
      if (!result) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.displayhide {
  display: none;
}
</style>
