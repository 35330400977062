<template>
  	<IndividualSoldPinReport />
</template>

<script>

// @ is an alias to /src
import IndividualSoldPinReport from "@/components/reports/IndividualSoldPinReport.vue";

export default {
  name: "IndividualSoldPinReportView",
  components: {
    IndividualSoldPinReport,
  },
};
</script>
