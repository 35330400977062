import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_APIRESELLERURL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');
axios.defaults.headers.common['support_admin_id'] = localStorage.getItem('support_admin_id');

Vue.config.productionTip = false;

Vue.directive('select', {
 twoWay: true,
 bind: function (el, binding, vnode) {
   $(el).select2().on("select2:select", (e) => {
     el.dispatchEvent(new Event('change', { target: e.target }));
   });
 },
 componentUpdated: function(el, me) {
   $(el).trigger("change");
 }
});


Vue.mixin({
  methods: {
    com_DecryptString(string, key = ''){
    	if(key == ''){
        key = process.env.VUE_APP_ENCRYPTIONKEY;   
      }

    	let str = atob(string);
    	var output = "";
        for( var i = 0; i < str.length; ) {
            for( var j = 0; ( j < key.length && i < str.length ); j++, i++ ) {
               output += String.fromCharCode( str[i].charCodeAt( 0 ) ^ key[j].charCodeAt( 0 ) );
            }
        }
        return output;
    },

    com_EncryptString(string, key = ''){
      if(key == ''){
        key = process.env.VUE_APP_ENCRYPTIONKEY;   
      }
      
      let str = string.toString();   
      var output = "";
      for( var i = 0; i < str.length; ) {
        for( var j = 0; ( j < key.length && i < str.length ); j++, i++ ) {
          output += String.fromCharCode( str[i].charCodeAt( 0 ) ^ key[j].charCodeAt( 0 ) );
        }
      }
      return btoa(output);
    },

    com_setLoginData(param){
    	let rootObject = this;
    	if(param.jwt_token){
    		axios.defaults.headers.common['Authorization'] = 'Bearer ' + param.jwt_token;
    		axios.get('profile').then(function (response) {
    			localStorage.setItem("jwt_token", param.jwt_token);
    			rootObject.$store.dispatch('changLoginStatus', true);
		        rootObject.$store.dispatch('setUser', response.data.user); 
            rootObject.com_CheckVisitActivityAndTokenStatus();
		        if(param.redirect_url){
		    		rootObject.$router.push(param.redirect_url);
		    	}
		    })
	      	.catch(function (error) {
	        	let alertmessage = "";
	          	for (var prop in error.response.data) {
	            	alertmessage += ' '+error.response.data[prop]
	          	}
	          	localStorage.removeItem("jwt_token");
              localStorage.removeItem("support_admin_id");
		      	rootObject.$store.dispatch('setUser', null);
                rootObject.$store.dispatch('setImagePopupOpened', null);
		        rootObject.$store.dispatch('changLoginStatus', false);
            rootObject.com_CheckVisitActivityAndTokenStatus(false);
	          	console.log(alertmessage);
	          	if(param.redirect_url){
		    		rootObject.$router.push(param.redirect_url);
		    	}else{
                    rootObject.$router.push('logout'); 
                }
	      	});
    	}else{
    		localStorage.removeItem("jwt_token");
        localStorage.removeItem("support_admin_id");
		    rootObject.$store.dispatch('setUser', null);
            rootObject.$store.dispatch('setImagePopupOpened', null);
		    rootObject.$store.dispatch('changLoginStatus', false);
        rootObject.com_CheckVisitActivityAndTokenStatus(false);
		    if(param.redirect_url){
	    		rootObject.$router.push(param.redirect_url);
	    	}
    	}
    },

    com_setPanelData(param){
        var previousLoginStatus = this.$store.getters.getLoginStatus;
        var previousPanel = this.$store.getters.getPanel;
        var currentPanel = param.panel;

        localStorage.removeItem("panel");
        localStorage.setItem("panel", param.panel);
        this.$store.dispatch('setPanel', param.panel);
        this.$store.dispatch('setPanelData', param.panel_settings);

        if((previousLoginStatus) && (previousPanel != currentPanel))
        {
            let loginparam = {
                'jwt_token': '',
                'redirect_url':'login',
            };
            console.log('Wrong Panel');
            this.com_setLoginData(loginparam);
        }else{
            let loginparam = {
                'jwt_token': localStorage.getItem("jwt_token"),
            };
            this.com_setLoginData(loginparam);
        }
    },

    com_currentDateTime(format='', time = '') {
      //format = dmy, time = no
      const monthShortNames = [ 
                                "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                              ];

      var current = new Date();

      if(format=='dmy'){
        var date = ('0'+current.getDate()).slice(-2)
                     +' '
                     +monthShortNames[(current.getMonth())]
                     +', '
                     +current.getFullYear()
      }else{
        var date = current.getFullYear()
                   +'-'
                   +('0'+(current.getMonth()+1)).slice(-2)
                   +'-'
                   +('0'+current.getDate()).slice(-2);
      }

      if(time=='' || time=='yes'){
        var time = ('0'+current.getHours()).slice(-2) 
                    + ":" 
                    + ('0'+current.getMinutes()).slice(-2) 
                    + ":" 
                    + ('0'+current.getSeconds()).slice(-2);
      }else{
        time = '';
      }
      
      var dateTime = date +' '+ time;
      return dateTime;
    },

    com_currentDateRange(addition_days = 0) {
      //Current Date
      const current = new Date();
      const startDate = current.getFullYear()
                   +'-'
                   +('0'+(current.getMonth()+1)).slice(-2)
                   +'-'
                   +('0'+current.getDate()).slice(-2);

      //Last Date
      var lastDate = new Date();
      if(parseInt(addition_days) != 0){
        lastDate.setDate(lastDate.getDate() + parseInt(addition_days));
      }
      
      const endDate = lastDate.getFullYear()
                   +'-'
                   +('0'+(lastDate.getMonth()+1)).slice(-2)
                   +'-'
                   +('0'+lastDate.getDate()).slice(-2);
      
      if(endDate < startDate){
        var dateArr = {startDate:endDate, endDate:startDate};
      }else{
        var dateArr = {startDate:startDate, endDate:endDate};
      }
      
      return dateArr;
    },

    com_modifyDateTime(no_of_days = 0, date = '') {
      if(date != ""){
        var newDate = new Date(date);
      }else{
        var newDate = new Date();
      }
      
      if(parseInt(no_of_days) != 0){
        newDate.setDate(newDate.getDate() + parseInt(no_of_days));
      }

      var modifiedDate = newDate.getFullYear()
                        +'-'
                        +('0'+(newDate.getMonth()+1)).slice(-2)
                        +'-'
                        +('0'+newDate.getDate()).slice(-2)
                        +' '+('0'+newDate.getHours()).slice(-2)+':'
                        +('0'+newDate.getMinutes()).slice(-2)+':'
                        +('0'+newDate.getSeconds()).slice(-2);
      return modifiedDate;
    },

    com_displayDateFormat(date) {
      var newDate = new Date(date);
      var resultArr = [];
      var convertedDate   = newDate.getFullYear()
                      +'-'
                      +('0'+(newDate.getMonth()+1)).slice(-2)
                      +'-'
                      +('0'+newDate.getDate()).slice(-2);
   
      var convertedHour = newDate.getHours();

      resultArr = {date:convertedDate, hour:convertedHour};
      return resultArr;
    },

    com_pageNotFound(){
    	this.$router.push('pagenotfound');
    },

    com_verifyAllowOrNotSetting(){
      var panelData = this.$store.getters.getPanelData;
      var encryptedData = this.$store.getters.getUser;
      var decodedString  = this.com_DecryptString(encryptedData);
      var userData = JSON.parse(decodedString);
      if((userData.id_reseller != panelData.id_reseller) || (userData.user_level != panelData.reseller_level))
      {
        return false
      }else{
        return true;
      }
    }, 

    com_removeHostedPinPrefix(actualPin, prefix, panel_type){
      if(panel_type == 'hosted'){
        var newPin = actualPin.replace(prefix, "");
        var hostedPin = newPin;
        if(newPin.charAt(0)==='-'){
          hostedPin = newPin.substring(1);
        }
        return hostedPin;
      }else{
        if (actualPin.indexOf("HR4") >= 0){
          var getHyphenPos = actualPin.indexOf("-");
          var filteredPin = actualPin.substring(getHyphenPos+1);
          return filteredPin;
        }
        return actualPin;
      }
    },

    com_user_type_access(aff_type_arr, do_redirect = false, resellerType = false){
      //Defined in Database........
      //aff_type_arr = 0 ; Only Reseller
      //aff_type_arr = 1 ; Reseller + Affiliate

      /*
      var encryptedData = this.$store.getters.getUser;
      var decodedString  = this.com_DecryptString(encryptedData);
      var userData = JSON.parse(decodedString);
      */
    
      return true;
    },

    com_errorHandel(error){
      if(error.response.status==401){
        let loginparam = {
          'jwt_token': '',
          'redirect_url':'login',
        };
        this.com_setLoginData(loginparam);
      }
    },

    com_CheckVisitActivityAndTokenStatus(startInterval = true){
      var rootObject = this;
      var intervalObj = null;

      // system will auto logout after this idle time
      var maxIdleTime = process.env.VUE_APP_MAXIDLETIME; 

      //Check system activity after this interval
      var intervalTime = process.env.VUE_APP_CHECKACTIVITYINTERVALTIME;

      // after this tim system will go for refressh token
      var tokenRefreshTime = process.env.VUE_APP_TOKENREFRESHTIME; 

      if(startInterval){
        intervalObj = setInterval(function(){  
          var lastActivityTime = localStorage.getItem('lastActivityTime');
          var lasttokenUpdateTime = localStorage.getItem('tkutim');
          var current = new Date();
          var totalIdleTime = parseInt(lastActivityTime)+parseInt(maxIdleTime);
          //console.log("Hello");
          //console.log(totalIdleTime +" _ "+current.getTime());
          if ( totalIdleTime < current.getTime()) {
            //This is Logout
            intervalObj = localStorage.getItem("visitActvityProcess");
            clearInterval(intervalObj);
            let loginparam = {
              'jwt_token': '',
              'redirect_url':'login',
            };
            rootObject.com_setLoginData(loginparam);
          }else{
            var nextTokenRefreshTime = parseInt(lasttokenUpdateTime)+parseInt(tokenRefreshTime);
            if( current.getTime() >= nextTokenRefreshTime){
              rootObject.com_RefreshToken();
            }
          }
        }, intervalTime);
        localStorage.setItem('visitActvityProcess', intervalObj);
      }else{
        //Clear Interval 
        intervalObj = localStorage.getItem("visitActvityProcess");
        if(intervalObj){
          clearInterval(intervalObj);
          localStorage.removeItem("visitActvityProcess");
        }
      }
    },

    com_SetLastActivityTime(){
      var current = new Date();
      localStorage.setItem('lastActivityTime', current.getTime());
    },

    com_UpdateTokenAndTime(token){
      var current = new Date();
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("tkutim");
      localStorage.setItem("jwt_token", token);
      localStorage.setItem("tkutim", current.getTime());
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      //console.log("token updated");
    },

    com_RefreshToken(){
      var rootObject = this;
      axios.get('refresh-token').then(function (response) {
        rootObject.com_UpdateTokenAndTime(response.data.token);
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += ' '+error.response.data[prop]
        }
        console.log(alertmessage);
        rootObject.com_errorHandel(error);
      });
    },

    com_onlineReportAccess(){
      return true;
      /*
      var encryptedData = this.$store.getters.getUser;
      var decodedString  = this.com_DecryptString(encryptedData);
      var userData = JSON.parse(decodedString);
      if(userData.user_level==4 && userData.reseller4.has_inapp_purchase==1){
        return true;
      }else{
        return false;
      }
      */
    },

  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

